import clsx from "clsx";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { colors } from "../../../ui/colors";
import { PlusIcon } from "../../../ui/icons/plus";
import Avatar from "../../../ui/molecules/avatar";
import Button from "../../../ui/molecules/button";
import { Spinner } from "../../../ui/molecules/spinner";
import { ContactStateEnum } from "../../targetList/dto";
import { EditCompanyForm } from "./editCompanyForm";
import { editCompany, findCompany, setSelectedCompany } from "./slice";

export function EditCompany() {
  const companiesState = useAppSelector((state) => state.companies);
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companiesState.selectedCompany !== undefined) {
      dispatch(findCompany(companiesState.selectedCompany));
    }
  }, [companiesState.selectedCompany]);

  return (
    <div className="h-full overflow-auto">
      {companiesState.findCompanyStatus === "loading" ||
      targetListsState.findTargetListStatus === "loading" ? (
        <Spinner />
      ) : (
        <div
          className={clsx("h-full", {
            "border-t-green-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              targetListsState.findTargetListResponse?.companyContacts &&
              targetListsState.findTargetListResponse?.companyContacts[
                companiesState.findCompanyResponse?.id!
              ] &&
              targetListsState.findTargetListResponse?.companyContacts[
                companiesState.findCompanyResponse?.id!
              ].contactState === ContactStateEnum.LEADTAKEN,
            "border-t-orange-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              targetListsState.findTargetListResponse?.companyContacts &&
              targetListsState.findTargetListResponse?.companyContacts[
                companiesState.findCompanyResponse?.id!
              ] &&
              targetListsState.findTargetListResponse?.companyContacts[
                companiesState.findCompanyResponse?.id!
              ].contactState === ContactStateEnum.TORECALL,
            "border-t-red-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              targetListsState.findTargetListResponse?.companyContacts &&
              targetListsState.findTargetListResponse?.companyContacts[
                companiesState.findCompanyResponse?.id!
              ] &&
              targetListsState.findTargetListResponse?.companyContacts[
                companiesState.findCompanyResponse?.id!
              ].contactState === ContactStateEnum.KO,
            "border-t-neutral-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              targetListsState.findTargetListResponse?.companyContacts &&
              targetListsState.findTargetListResponse?.companyContacts[
                companiesState.findCompanyResponse?.id!
              ] &&
              targetListsState.findTargetListResponse?.companyContacts[
                companiesState.findCompanyResponse?.id!
              ].contactState === ContactStateEnum.INCORRECT,
            "border-t-brandPrimary-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              targetListsState.findTargetListResponse?.companyContacts &&
              targetListsState.findTargetListResponse?.companyContacts[
                companiesState.findCompanyResponse?.id!
              ] &&
              targetListsState.findTargetListResponse?.companyContacts[
                companiesState.findCompanyResponse?.id!
              ].contactState === ContactStateEnum.DRAFT,
          })}
        >
          <div className="px-6 pt-4 flex items-center justify-between">
            <Avatar
              type="info"
              imageUrl=""
              firstRow={companiesState.findCompanyResponse?.name!}
              size="md"
              altTextInitials={
                companiesState.findCompanyResponse?.name
                  .slice(0, 1)
                  .toUpperCase()!
              }
            />
            <div className="flex items-center gap-4">
              <Button
                size={"sm"}
                iconPosition={"off"}
                variant={"outline"}
                color={"blue"}
                label="Salva"
                onClick={() =>
                  dispatch(
                    editCompany({
                      id: companiesState.findCompanyResponse?.id!,
                      data: companiesState.editCompanyRequest,
                    })
                  )
                }
              />
              <div
                className="cursor-pointer rotate-45"
                onClick={() => dispatch(setSelectedCompany(undefined))}
              >
                <PlusIcon color={colors.neutral[500]} size={20} />
              </div>
            </div>
          </div>
          <div className="py-4">
            <EditCompanyForm />
          </div>
        </div>
      )}
    </div>
  );
}
