import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { Layout } from "../../../../ui/layout";
import { MenuItems } from "../../../../ui/organisms/navbar/dto";
import { setTabValue } from "../../slice";
import { Step1 } from "./step1";
import { Step2 } from "./step2";

export function ImportCompanies() {
  const companiesState = useAppSelector((state) => state.companies);
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [name, setName] = useState("");
  const [delimiter, setDelimiter] = useState("");
  const [fields, setFields] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [rows, setRows] = useState<Record<string, any>[]>([]);

  const handleFileChange = (e: File[]) => {
    const file = e[0];
    setError(false);
    if (file) {
      if (file.type !== "text/csv") {
        setError(true);
        setName("");
        setDelimiter("");
        setFields([]);
        setRows([]);
        setFile(null);
      } else {
        setName(file.name);
        Papa.parse(file, {
          complete: (result) => {
            setDelimiter(result.meta.delimiter);
            setFields(result.meta.fields ?? []);
            //@ts-ignore
            setRows(result.data);
            setFile(file);
          },
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });
      }
    }
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      companiesState.importCSVStatus === "failed" ||
      companiesState.importCSVStatus === "successfully"
    ) {
      navigate("/database");
      dispatch(setTabValue(1));
    }
  }, [companiesState.importCSVStatus]);

  return (
    <Layout
      menuItem={MenuItems.DATABASE}
      breadcrumbItems={["Database", "Importa aziende"]}
      headerLabel="Importa aziende"
      headerBackPath="/database"
    >
      <div className="rounded-xl m-6 flex flex-col bg-white gap-10 justify-between max-h-full">
        {step === 0 && (
          <Step1
            step={step}
            setStep={function (value: number): void {
              setStep(value);
            }}
            handleChange={function (e: File[]): void {
              handleFileChange(e);
            }}
            error={error}
            file={file}
          />
        )}
        {step === 1 && (
          <Step2
            step={step}
            setStep={function (value: number): void {
              setStep(value);
            }}
            name={name}
            delimiter={delimiter}
            fields={fields}
            file={file}
            rows={rows}
          />
        )}
      </div>
    </Layout>
  );
}
