import { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { setOption } from "../../slice";
import { ColumnsOptions } from "./columns";
import { DefaultOption } from "./default";

export function OptionsPeople() {
    const databaseState = useAppSelector(state => state.database)
    const dispatch = useAppDispatch()

    const optionMap = new Map<'columns' | undefined, ReactElement>([
        [undefined, <DefaultOption />],
        ['columns', <ColumnsOptions />],
    ])

    useEffect(() => {
        dispatch(setOption(undefined))
    }, [databaseState.optionsActive])

    return (
        <div className="h-full flex-shrink-0 relative bg-white border-l-[1px] border-neutral-100">
            {optionMap.get(databaseState.option)}
        </div>
    )
}