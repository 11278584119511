import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import { DatabasePreferences, SortingProps } from "./dto";
import { SortableItem } from "./item";

export function Sorting(props: SortingProps) {
  const [rows, setRows] = useState<
    {
      item: DatabasePreferences;
      uniqueIdentifier: UniqueIdentifier;
    }[]
  >([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const newRow: {
      item: DatabasePreferences;
      uniqueIdentifier: UniqueIdentifier;
    }[] = [];
    props.items.forEach((size) => {
      if (size !== undefined) {
        newRow.push({
          item: size,
          uniqueIdentifier: size.column,
        });
      }
    });
    setRows(newRow);
  }, [props.items]);

  return (
    <>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={rows.map((row) => row.uniqueIdentifier)}>
          {rows.map(
            (id) =>
              id !== undefined && (
                <SortableItem
                  sort={(_visible) => {
                    const newItems = [...props.items];
                    const itemToUpdate = newItems.find(
                      (item) => item.column === id.item.column
                    );
                    if (itemToUpdate) {
                      const updatedItem = {
                        ...itemToUpdate,
                        visible: _visible,
                      };
                      const index = newItems.findIndex(
                        (item) => item.column === id.item.column
                      );
                      newItems[index] = updatedItem;
                      props.sortItems([...newItems]);
                      const newRows = rows.map((row) =>
                        row.uniqueIdentifier === id.uniqueIdentifier
                          ? { ...row, item: updatedItem }
                          : row
                      );
                      setRows(newRows);
                    }
                  }}
                  key={id.uniqueIdentifier}
                  id={id}
                />
              )
          )}
        </SortableContext>
      </DndContext>
    </>
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over === null) {
      return;
    }

    if (active.id !== over.id) {
      setRows(
        (
          rows: {
            item: DatabasePreferences;
            uniqueIdentifier: UniqueIdentifier;
          }[]
        ) => {
          const oldIndex = rows.findIndex(
            (item) => item.uniqueIdentifier === active.id
          );
          const newIndex = rows.findIndex(
            (item) => item.uniqueIdentifier === over.id
          );

          props.sortItems(
            arrayMove(rows, oldIndex, newIndex).map((item) => item.item)
          );

          return arrayMove(rows, oldIndex, newIndex);
        }
      );
    }
  }
}
