import { ReactComponent as NotFoundIcon } from '../../icons/notFound.svg';

export function NotFound() {
    return (
        <div className='flex flex-col gap-2 items-center mb-6'>
            <div className='h-[350px] w-[350px]'>
                <NotFoundIcon />
            </div>
            <span className='text-heading-sm font-semibold text-neutral-600'>Nessun risultato trovato</span>
            <span className='text-text-lg text-neutral-500'>Non siamo riusciti a trovare quello che stavi cercando</span>
        </div>
    )
}
