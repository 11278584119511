import {
  EditProjectRequest,
  FindAllProjectsFilters,
  FindAllProjectsResponse,
  NewProjectRequest,
  ProjectDTO,
  ProjectStateEnum,
} from "./dto";
import { ProjectsServiceImpl } from "./serviceImpl";

export interface ProjectsService {
  findAllProjects(
    filters: FindAllProjectsFilters
  ): Promise<FindAllProjectsResponse>;
  createProject(request: NewProjectRequest): Promise<string>;
  deleteProject(id: string): Promise<void>;
  editProject(id: string, data: EditProjectRequest): Promise<void>;
  findProjectById(id: string): Promise<ProjectDTO>;
  changeStatus(
    request: {
      state:
        | ProjectStateEnum.CONCLUDED
        | ProjectStateEnum.INPROGRESS
        | undefined;
      id: string;
    }[]
  ): Promise<void>;
}

export function NewProjectsService(): ProjectsService {
  return new ProjectsServiceImpl();
}
