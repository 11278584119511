import qs from "qs";
import { cdAxios } from "../../../lib/axios";
import { keycloak } from "../../../lib/keycloak";
import { ImportCSVResponseDTO } from "../dto";
import {
  CompanyDTO,
  EditCompanyRequest,
  FindAllCompaniesFilters,
  FindAllCompaniesResponse,
} from "./dto";
import { CompaniesService } from "./service";

export class CompaniesServiceImpl implements CompaniesService {
  public findAllCompanies(
    filters: FindAllCompaniesFilters
  ): Promise<FindAllCompaniesResponse> {
    return cdAxios
      .get("/api/companies", {
        params: {
          itemsPerPage:
            filters.itemsPerPage === 0 ? undefined : filters.itemsPerPage,
          page: filters.page,
          sort: filters.sort,
          order: filters.order,
          privacy: filters.privacy?.value,
          revenue: filters.revenue?.value,
          employeesNumber: filters.employees?.value,
          smartphone: filters.smartphone?.value,
          atecoCode: filters.atecos.map((ateco) => "~:" + ateco.value),
          category: filters.category.map((category) => category.value),
          department: filters.department.map((department) => department.value),
          targetList: filters.targetList.map((target) => "=:" + target.value),
          outcome: filters.outcome.map((outcome) => outcome.value),
          project: filters.project.map((project) => "=:" + project.value),
          name: filters.name.map((name) => name.label),
          role: filters.role.map((role) => role.value),
          sector: filters.sector.map((sector) => sector.value),
          state: filters.state?.value,
          city: filters.city.map((city) => "~:" + city.value),
          countryRegion: filters.countryRegion.map(
            (countryRegion) => countryRegion.value
          ),
          zipCode: filters.zipCode.map((zipCode) => zipCode.value),
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: false,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public deleteCompany(id: string): Promise<void> {
    return cdAxios
      .delete("/api/companies/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public findCompanyById(id: string): Promise<CompanyDTO> {
    return cdAxios
      .get("/api/companies/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public editCompany(id: string, data: EditCompanyRequest): Promise<void> {
    return cdAxios
      .put("/api/companies/" + id, data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public importContact(data: FormData): Promise<ImportCSVResponseDTO> {
    return cdAxios
      .post("/api/companies/csv", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }
}
