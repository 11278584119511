import {
  FindAllParticularitiesResponse,
  ParticularitiesFilters,
  UpsertParticularityDTO,
} from "./dto";
import { ParticularitiesServiceImpl } from "./serviceImpl";

export interface ParticularitiesService {
  findAllParticularities(
    filters: ParticularitiesFilters
  ): Promise<FindAllParticularitiesResponse>;
  createParticularity(request: UpsertParticularityDTO): Promise<string>;
  editParticularity(request: UpsertParticularityDTO, id: string): Promise<void>;
  deleteParticularity(id: string): Promise<void>;
}

export function NewParticularitiesService(): ParticularitiesService {
  return new ParticularitiesServiceImpl();
}
