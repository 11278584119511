import clsx from "clsx";
import { useEffect, useState } from "react";
import { colors } from "../../../ui/colors";
import { LinkedinIcon } from "../../../ui/icons/linkedin";
import { MailIcon } from "../../../ui/icons/mail";
import { MinusIcon } from "../../../ui/icons/minus";
import { PlusIcon } from "../../../ui/icons/plus";
import Input from "../../../ui/molecules/input";
import { PersonDTO } from "../people/dto";

interface Props {
  employee: PersonDTO;
}
export function AccordionEmployee(props: Props) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      className="w-full border-t border-neutral-200 transition-all"
      key={props.employee.id}
    >
      <div
        className={clsx(
          "h-[50px] px-6 py-4 flex items-center justify-between w-full transition-all",
          {
            "bg-white": !open,
            "bg-neutral-50": open,
          }
        )}
      >
        <span className="text-label-sm text-brandPrimary-500 font-medium uppercase">
          {props.employee.name + " " + props.employee.surname}
        </span>
        <div className="cursor-pointer" onClick={() => setOpen(!open)}>
          {open ? (
            <MinusIcon size={16} color={colors.neutral[500]} />
          ) : (
            <PlusIcon size={16} color={colors.neutral[500]} />
          )}
        </div>
      </div>
      <div
        className={clsx(
          "w-full px-6 pb-4 bg-neutral-50 flex flex-col gap-4 transition-all",
          {
            hidden: !open,
          }
        )}
      >
        <div className="flex gap-3 items-center">
          <Input disabled label="Nome" value={props.employee.name} />
          <Input disabled label="Cognome" value={props.employee.surname} />
        </div>
        <Input disabled label="Ruolo" value={props.employee.role} />
        <Input disabled label="Reparto" value={props.employee.department} />
        <Input
          disabled
          label="Permessi e privacy"
          value={props.employee.privacy ? "Sì" : "No"}
        />
        <Input disabled label="Cellulare" value={props.employee.phone} />
        <a
          rel="noreferrer"
          target="_blank"
          href={"mailto:" + props.employee.email}
          className="flex items-center gap-2 text-text-sm font-medium text-neutral-600"
        >
          <MailIcon size={20} color={colors.neutral[500]} />
          <span>{props.employee.email}</span>
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href={props.employee.linkedinProfile}
          className="flex items-center gap-2 text-text-sm font-medium text-neutral-600"
        >
          <LinkedinIcon size={20} color={colors.neutral[500]} />
          <span>
            {props.employee.linkedinProfile &&
              props.employee.linkedinProfile.slice(0, 35) + "..."}
          </span>
        </a>
      </div>
    </div>
  );
}
