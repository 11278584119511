import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Spinner } from "../../../ui/molecules/spinner";
import { Banner } from "../../../ui/organisms/banner";
import { NotFound } from "../../../ui/organisms/notFound";
import { findAllCompanies, setEditCompanyStatus } from "../companies/slice";
import { setTabValue } from "../slice";
import { Filters } from "./filters";
import { PeopleHorizontalFilters } from "./filters/horizontalFilters";
import { PeopleList } from "./list";
import {
  findAllPeople,
  setAssociatePersonToCompanyStatus,
  setDeletePersonStatus,
  setEditPersonStatus,
  setFindAllPeopleFiltersPage,
  setPersonToAddCompany,
  setSelectedPerson,
} from "./slice";

export function People() {
  const databaseState = useAppSelector((state) => state.database);
  const peopleState = useAppSelector((state) => state.people);
  const companiesState = useAppSelector((state) => state.companies);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isFirstLoadPeople, setIsFirstLoadPeople] = useState(true);

  useEffect(() => {
    dispatch(findAllPeople({ ...peopleState.filters, targetList: [] }));
    setIsFirstLoadPeople(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoadPeople) {
      dispatch(setFindAllPeopleFiltersPage(0));
      dispatch(findAllPeople({ ...peopleState.filters, page: 0 }));
    }
  }, [
    peopleState.filters.order,
    peopleState.filters.sort,
    peopleState.filters.personName,
    peopleState.filters.personEmail,
    peopleState.filters.hasCompany,
    peopleState.filters.atecos,
    peopleState.filters.category,
    peopleState.filters.city,
    peopleState.filters.countryRegion,
    peopleState.filters.department,
    peopleState.filters.employees,
    peopleState.filters.hasCompany,
    peopleState.filters.name,
    peopleState.filters.privacy,
    peopleState.filters.revenue,
    peopleState.filters.role,
    peopleState.filters.sector,
    peopleState.filters.smartphone,
    peopleState.filters.state,
    peopleState.filters.zipCode,
    peopleState.filters.targetList,
    peopleState.filters.project,
    peopleState.filters.outcome,
  ]);

  useEffect(() => {
    if (!isFirstLoadPeople) dispatch(findAllPeople(peopleState.filters));
  }, [peopleState.filters.page]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      peopleState.deletePersonStatus === "failed" ||
      peopleState.deletePersonStatus === "successfully"
    ) {
      if (peopleState.deletePersonStatus === "successfully") {
        dispatch(findAllPeople(peopleState.filters));
      }
      timeoutId = setTimeout(() => {
        dispatch(setDeletePersonStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [peopleState.deletePersonStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      companiesState.editCompanyStatus === "failed" ||
      companiesState.editCompanyStatus === "successfully"
    ) {
      if (companiesState.editCompanyStatus === "successfully") {
        dispatch(findAllCompanies(companiesState.filters));
      }
      timeoutId = setTimeout(() => {
        dispatch(setEditCompanyStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [companiesState.editCompanyStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      peopleState.editPersonStatus === "failed" ||
      peopleState.editPersonStatus === "successfully"
    ) {
      if (peopleState.editPersonStatus === "successfully") {
        dispatch(setSelectedPerson(undefined));
        dispatch(findAllPeople(peopleState.filters));
      }
      timeoutId = setTimeout(() => {
        dispatch(setEditPersonStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [peopleState.editPersonStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      peopleState.associatePersonToCompanyStatus === "failed" ||
      peopleState.associatePersonToCompanyStatus === "successfully"
    ) {
      if (peopleState.associatePersonToCompanyStatus === "successfully") {
        dispatch(setPersonToAddCompany(undefined));
        dispatch(findAllPeople(peopleState.filters));
      }
      timeoutId = setTimeout(() => {
        dispatch(setAssociatePersonToCompanyStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [peopleState.associatePersonToCompanyStatus]);

  useEffect(() => {
    if (
      peopleState.importCSVStatus === "failed" ||
      peopleState.importCSVStatus === "successfully"
    ) {
      navigate("/database");
      dispatch(setTabValue(0));
    }
  }, [peopleState.importCSVStatus]);

  return (
    <div className="">
      <div className="absolute flex flex-col gap-4 right-6">
        <Banner
          label={"Persona eliminata."}
          visible={peopleState.deletePersonStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setDeletePersonStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'eliminazione della persona."
          }
          visible={peopleState.deletePersonStatus === "failed"}
          closeAction={function (): void {
            dispatch(setDeletePersonStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={"Persona associata correttamente all'azienda."}
          visible={
            peopleState.associatePersonToCompanyStatus === "successfully"
          }
          closeAction={function (): void {
            dispatch(setAssociatePersonToCompanyStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'associazione della persona all'azienda."
          }
          visible={peopleState.associatePersonToCompanyStatus === "failed"}
          closeAction={function (): void {
            dispatch(setAssociatePersonToCompanyStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={"Persona aggiornata."}
          visible={peopleState.editPersonStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setEditPersonStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={"Si è verificato un errore durante la modifica della persona."}
          visible={peopleState.editPersonStatus === "failed"}
          closeAction={function (): void {
            dispatch(setEditPersonStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={"Azienda aggiornata."}
          visible={companiesState.editCompanyStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setEditCompanyStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={"Si è verificato un errore durante la modifica dell'azienda."}
          visible={companiesState.editCompanyStatus === "failed"}
          closeAction={function (): void {
            dispatch(setEditCompanyStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      <div className="flex max-h-full">
        <div
          className={clsx("transition-all duration-300 w-[25%] flex-shrink-0", {
            block: databaseState.filtersActive,
            hidden: !databaseState.filtersActive,
          })}
        >
          <Filters />
        </div>
        <div
          className={clsx({
            "w-full": !databaseState.filtersActive,
            "w-9/12": databaseState.filtersActive,
          })}
        >
          {peopleState.findAllStatus === "loading" ? (
            <div className="w-full h-[45%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div>
              <div className="bg-white h-16 flex items-center px-4">
                <span className="text-heading-xs font-semibold text-neutral-800">
                  Persone
                </span>
              </div>
              <PeopleHorizontalFilters />
              {peopleState.findAllResponse?.data.length === 0 ||
              peopleState.findAllResponse?.data === null ? (
                <NotFound />
              ) : (
                <PeopleList />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
