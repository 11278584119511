import { cdAxios } from "../../lib/axios";
import { keycloak } from "../../lib/keycloak";
import {
  FindAllParticularitiesResponse,
  ParticularitiesFilters,
  UpsertParticularityDTO,
} from "./dto";
import { ParticularitiesService } from "./service";

export class ParticularitiesServiceImpl implements ParticularitiesService {
  public findAllParticularities(
    filters: ParticularitiesFilters
  ): Promise<FindAllParticularitiesResponse> {
    return cdAxios
      .get("/api/particularities", {
        params: {
          itemsPerPage: filters.itemsPerPage,
          page: filters.page,
          sort: filters.sort,
          order: filters.order,
          status: filters.status,
          name: filters.name
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public createParticularity(request: UpsertParticularityDTO): Promise<string> {
    return cdAxios
      .post("/api/particularities", request, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public editParticularity(
    request: UpsertParticularityDTO,
    id: string
  ): Promise<void> {
    return cdAxios
      .put("/api/particularities/" + id, request, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public deleteParticularity(id: string): Promise<void> {
    return cdAxios
      .delete("/api/particularities/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }
}
