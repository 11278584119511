import { ReactElement } from "react";
import { useAppDispatch } from "../../../../lib/redux/hooks";
import { CompanyDTO } from "../../../database/companies/dto";
import { setSelectedCompany } from "../../../database/companies/slice";

interface Props {
  company: CompanyDTO;
  column: string;
}
export function CompanyColumnMap(props: Props) {
  const dispatch = useAppDispatch();

  const ColumnMap = new Map<string, ReactElement>([
    [
      "Azienda",
      <td
        onClick={() => dispatch(setSelectedCompany(props.company.id))}
        className="px-[4px] cursor-pointer min-w-[150px] text-text-md font-semibold text-brandPrimary-600"
      >
        {props.company.name}
      </td>,
    ],
    [
      "Fatturato",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        {props.company.revenue ?? "-"}
      </td>,
    ],
    [
      "Telefono",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        {props.company.phone ?? "-"}
      </td>,
    ],
    [
      "Email",
      <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
        {props.company.email ?? "-"}
      </td>,
    ],
    [
      "Indirizzo",
      <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
        {props.company.address ?? "-"}
      </td>,
    ],
    [
      "CAP",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        {props.company.zipCode ?? "-"}
      </td>,
    ],
    [
      "Città",
      <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-neutral-600">
        {props.company.city ?? "-"}
      </td>,
    ],
    [
      "Provincia",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        {props.company.province ?? "-"}
      </td>,
    ],
    [
      "Regione",
      <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-neutral-600">
        {props.company.countryRegion ?? "-"}
      </td>,
    ],
    [
      "Stato",
      <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-neutral-600">
        {props.company.state ?? "-"}
      </td>,
    ],
    [
      "P. IVA",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        {props.company.vat ?? "-"}
      </td>,
    ],
    [
      "Cod. ATECO",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        {props.company.atecoCode ?? "-"}
      </td>,
    ],
    [
      "Settore",
      <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-neutral-600">
        {props.company.sector ?? "-"}
      </td>,
    ],
    [
      "Sito web",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        {props.company.website ?? "-"}
      </td>,
    ],
    [
      "Categoria",
      <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-neutral-600">
        {props.company.category ?? "-"}
      </td>,
    ],
    [
      "Dipendenti",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        {props.company.employeesNumber ?? "-"}
      </td>,
    ],
  ]);

  return <>{ColumnMap.get(props.column)}</>;
}
