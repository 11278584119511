import clsx from "clsx";
import { ReactElement } from "react";
import { colors } from "../../../ui/colors";
import { CheckCircleIcon } from "../../../ui/icons/checkCircle";
import { CircleIcon } from "../../../ui/icons/circle";
import { LinkedinIcon } from "../../../ui/icons/linkedin";
import { PersonDTO } from "./dto";
import { setSelectedPerson } from "./slice";
import { useAppDispatch } from "../../../lib/redux/hooks";

interface Props {
  column: string;
  index: number;
  person: PersonDTO;
}
export function Cell(props: Props) {
  const { column, index, person } = props;

  const dispatch = useAppDispatch();

  const cellMap = new Map<string, ReactElement>([
    [
      "Contatto",
      <div
        onClick={() => dispatch(setSelectedPerson(person.id))}
        className="text-text-md cursor-pointer font-semibold text-neutral-800 w-full"
      >
        {(person.name ?? "-") + " " + (person.surname ?? "-")}
      </div>,
    ],
    [
      "Telefono",
      <a
        rel="noreferrer"
        target="_blank"
        href={"tel:" + person.phone}
        className="text-text-sm text-neutral-600"
      >
        {person.phone}
      </a>,
    ],
    [
      "Email",
      <a
        rel="noreferrer"
        target="_blank"
        href={"mailto:" + person.email}
        className="text-text-sm text-neutral-600"
      >
        {person.email}
      </a>,
    ],
    [
      "Azienda",
      <span className="text-text-sm font-semibold text-brandPrimary-500">
        {person.companyName}
      </span>,
    ],
    [
      "Profilo Linkedin",
      <a
        rel="noreferrer"
        target="_blank"
        href={person.linkedinProfile}
        className="w-full flex justify-center"
      >
        <LinkedinIcon color={colors.neutral[400]} size={20} />
      </a>,
    ],
    [
      "Ruolo",
      <span className="text-text-sm text-neutral-600">{person.role}</span>,
    ],
    [
      "Reparto",
      <span className="text-text-sm text-neutral-600">
        {person.department}
      </span>,
    ],
    [
      "Privacy",
      <div className="w-full flex justify-center">
        {person.privacy ? (
          <CheckCircleIcon color={colors.neutral[400]} size={20} />
        ) : (
          <CircleIcon color={colors.neutral[400]} size={20} />
        )}
      </div>,
    ],
  ]);

  return (
    <td
      className={clsx({
        "pr-[4px] pl-[12px]": index === 0,
        "px-[4px]": index !== 0,
      })}
    >
      {cellMap.get(column)}
    </td>
  );
}
