import { cdAxios } from "../../lib/axios";
import { keycloak } from "../../lib/keycloak";
import {
    CreatePreferenceRequest,
    FindAllPreferencesResponse,
} from "../../ui/organisms/sorting/dto";
import { AtecoDTO } from "./dto";
import { PreferencesService } from "./service";

export class PreferencesServiceImpl implements PreferencesService {
  public findAllPreferences(
    owner: string
  ): Promise<FindAllPreferencesResponse> {
    return cdAxios
      .get("/api/preferences", {
        params: {
          owner: owner,
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public createPreferences(data: CreatePreferenceRequest): Promise<void> {
    return cdAxios
      .post("/api/preferences", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public updatePreferences(
    data: CreatePreferenceRequest,
    id: string
  ): Promise<void> {
    return cdAxios
      .put("/api/preferences/" + id, data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public findAllAtecos(): Promise<AtecoDTO[]> {
    return cdAxios
      .get("/api/atecos", {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }
}
