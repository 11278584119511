import clsx from "clsx";
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/lib/redux/hooks";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import Button from "../../../ui/molecules/button";
import { Dropdown } from "../../../ui/organisms/dropdown";
import { Pagination } from "../../../ui/organisms/pagination/index";
import { Cell } from "./cell";
import { Head } from "./head";
import {
  findEmployees,
  setFindAllCompaniesFiltersPage,
  setIdToDeleteCompany,
  setSelectedCompany,
  setSelectedCompanyNameForEmployees,
} from "./slice";

export function CompaniesList() {
  const companiesState = useAppSelector((state) => state.companies);
  const preferencesState = useAppSelector((state) => state.preferences);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companiesState.deleteCompanyStatus === "successfully") {
      dispatch(setIdToDeleteCompany(undefined));
    }
  }, [companiesState.deleteCompanyStatus]);

  return (
    <div className="h-full">
      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase text-text-xs">
            <tr className="bg-neutral-50">
              {preferencesState.findAllResponse?.data[0].companyPreferences
                .filter((column) => column.visible)
                .map((column, index) => (
                  <Head
                    key={column.column}
                    column={column.column}
                    index={index}
                  />
                ))}
              <th className={clsx("text-left")}></th>
              <th className="text-left pr-[12px] pl-[4px]">
                <div className="flex gap-[4px] px-[4px] w-[20px]" />
              </th>
            </tr>
          </thead>
          <tbody>
            {companiesState.findAllResponse?.data.map((company, index) => (
              <tr
                className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                key={company.email + index}
              >
                {preferencesState.findAllResponse?.data[0].companyPreferences
                  .filter((column) => column.visible)
                  .map((column, index) => (
                    <Cell
                      key={company.id + "-" + column.column}
                      column={column.column}
                      index={index}
                      person={company}
                    />
                  ))}
                <td className="min-w-[200px]">
                  <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"outline"}
                    color={"blue"}
                    label="Mostra dipendenti"
                    onClick={() => {
                      dispatch(findEmployees(company.employeesId));
                      dispatch(
                        setSelectedCompanyNameForEmployees(company.name)
                      );
                    }}
                  />
                </td>
                <td className="w-[20px] cursor-pointer">
                  <Dropdown
                    items={[
                      {
                        label: "Modifica",
                        icon: <EditIcon color={""} size={0} />,
                        onClick: () => dispatch(setSelectedCompany(company.id)),
                      },
                      {
                        label: "Cancella",
                        icon: <TrashIcon color={""} size={0} />,
                        onClick: () =>
                          dispatch(setIdToDeleteCompany(company.id)),
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        found={companiesState.findAllResponse?.total || 0}
        key={"pagination-companies-bottom"}
        pages={companiesState.findAllResponse?.totalPage || 0}
        currentPage={companiesState.findAllResponse?.page || 0}
        setPage={(page) => {
          dispatch(setFindAllCompaniesFiltersPage(page));
        }}
      />
    </div>
  );
}
