import React from "react";
import { IconProps } from "./dto";

export function ArrowRightIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33337 8.00065H12.6667M12.6667 8.00065L8.00004 3.33398M12.6667 8.00065L8.00004 12.6673" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}