import {
    CreatePreferenceRequest,
    FindAllPreferencesResponse,
} from "../../ui/organisms/sorting/dto";
import { AtecoDTO } from "./dto";
import { PreferencesServiceImpl } from "./serviceImpl";

export interface PreferencesService {
  findAllPreferences(owner: string): Promise<FindAllPreferencesResponse>;
  createPreferences(data: CreatePreferenceRequest): Promise<void>;
  updatePreferences(data: CreatePreferenceRequest, id: string): Promise<void>;
  findAllAtecos(): Promise<AtecoDTO[]>;
}

export function NewPreferencesService(): PreferencesService {
  return new PreferencesServiceImpl();
}
