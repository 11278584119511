import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { CheckCircleIcon } from "../../../../ui/icons/checkCircle";
import { SelectCustom } from "../../../../ui/molecules/select";
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter";
import { ContactStateEnum } from "../../../targetList/dto";
import {
    setFindAllPeopleFiltersOutcome
} from "../slice";

export function OutcomeFilter() {
  const peopleState = useAppSelector((state) => state.people);

  const dispatch = useAppDispatch();

  return (
    <AccordionFilter
      key={"person-filter-outcome"}
      label={"Esito"}
      icon={<CheckCircleIcon color={""} size={0} />}
      showClear={peopleState.filters.outcome.length > 0}
      options={peopleState.filters.outcome}
      clearAction={function (): void {
        dispatch(setFindAllPeopleFiltersOutcome([]));
      }}
      deleteFilter={function (option): void {
        dispatch(
          setFindAllPeopleFiltersOutcome(
            peopleState.filters.outcome.filter(
              (name) => name.value !== option.value
            )
          )
        );
      }}
    >
      <SelectCustom
        isMulti
        isClearable={false}
        onChange={(e) => {
          if (e !== undefined && typeof e !== "string") {
            dispatch(
              setFindAllPeopleFiltersOutcome(
                e.map((option) => ({ value: option, label: option }))
              )
            );
          }
        }}
        defaultValue={peopleState.filters.outcome.map((option) => option.value)}
        placeholder={"Cerca per esito"}
        options={[
          { label: "In attesa", value: ContactStateEnum.DRAFT },
          { label: "Lead preso", value: ContactStateEnum.LEADTAKEN },
          { label: "Da richiamare", value: ContactStateEnum.TORECALL },
          { label: "Ko", value: ContactStateEnum.KO },
          { label: "Info errate", value: ContactStateEnum.INCORRECT },
        ]}
        noOptionsMessage="Non sono presenti esiti."
      />
    </AccordionFilter>
  );
}
