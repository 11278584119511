import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { FiltersLinesIcon } from "../../../../../ui/icons/filtersLines";
import { UploadIcon } from "../../../../../ui/icons/upload";
import Button from "../../../../../ui/molecules/button";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { NotFound } from "../../../../../ui/organisms/notFound";
import { Filters } from "../../../../database/people/filters";
import { PeopleHorizontalFilters } from "../../../../database/people/filters/horizontalFilters";
import { findAllPeople } from "../../../../database/people/slice";
import { setFiltersActive } from "../../../../database/slice";
import { addContacts } from "../../../../targetList/slice";
import { ImportFromDatabaseList } from "./list";

export function ImportFromDatabase() {
  const targetListState = useAppSelector((state) => state.targetList);
  const databaseState = useAppSelector((state) => state.database);
  const peopleState = useAppSelector((state) => state.people);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(findAllPeople(peopleState.filters));
  }, []);

  return (
    <div className="flex max-h-full border-t">
      <div
        className={clsx("transition-all duration-300 w-[25%] flex-shrink-0", {
          block: databaseState.filtersActive,
          hidden: !databaseState.filtersActive,
        })}
      >
        <Filters />
      </div>
      <div
        className={clsx({
          "w-full": !databaseState.filtersActive,
          "w-9/12": databaseState.filtersActive,
        })}
      >
        {peopleState.findAllStatus === "loading" ? (
          <div className="w-full h-[45%] flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="bg-white h-16 flex items-center justify-between px-4">
              <span className="text-heading-xs font-semibold text-neutral-800">
                {"Database > Persone"}
              </span>
              <div className="flex items-center gap-2">
                {selectedIds.length > 0 && (
                  <Button
                    size={"sm"}
                    iconPosition={"left"}
                    variant={"outline"}
                    label={"Importa " + selectedIds.length + " selezionati"}
                    color={"blue"}
                    icon={<UploadIcon color={""} size={0} />}
                    onClick={() => {
                      dispatch(
                        addContacts({
                          targetListId:
                            targetListState.findTargetListResponse?.id!,
                          type: "persons",
                          contactsId: selectedIds,
                        })
                      );
                    }}
                  />
                )}
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={databaseState.filtersActive ? "outline" : "ghost"}
                  color={databaseState.filtersActive ? "blue" : "gray"}
                  icon={<FiltersLinesIcon color={""} size={0} />}
                  onClick={() => {
                    dispatch(setFiltersActive(!databaseState.filtersActive));
                  }}
                />
              </div>
            </div>
            <PeopleHorizontalFilters />
            {peopleState.findAllResponse?.data.length === 0 ||
            peopleState.findAllResponse?.data === null ? (
              <NotFound />
            ) : (
              <ImportFromDatabaseList
                selectedIds={selectedIds}
                setSelectedIds={(values) => setSelectedIds(values)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
