import { alwaysVisibleCompany } from "../../../../lib/preferences/dto";
import { updatePreferences } from "../../../../lib/preferences/slice";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { ChevronIcon } from "../../../../ui/icons/chevron";
import { PlusIcon } from "../../../../ui/icons/plus";
import { setOption, setOptionsActive } from "../../slice";
import { Sorting } from "../../../../ui/organisms/sorting";

export function ColumnsOptions() {
  const dispatch = useAppDispatch();

  const preferencesState = useAppSelector((state) => state.preferences);

  return (
    <div className="h-full overflow-auto">
      <div className="h-12 flex-shrink-0 px-[16px] py-[12px] flex items-center justify-between border-b-[1px]">
        <div className="flex items-center gap-1">
          <div
            className="cursor-pointer"
            onClick={() => dispatch(setOption(undefined))}
          >
            <ChevronIcon color={colors.neutral[400]} size={16} direction="l" />
          </div>
          <span className="text-label-md text-neutral-800 font-bold">
            Colonne
          </span>
        </div>
        <div
          className="rotate-45 cursor-pointer"
          onClick={() => dispatch(setOptionsActive(false))}
        >
          <PlusIcon color={colors.neutral[400]} size={24} />
        </div>
      </div>
      <div className="flex flex-col gap-1 px-1 py-2 relative overflow-auto mb-4">
        <div className="flex items-center justify-between px-4 py-3">
          <span className="text-label-sm font-medium text-neutral-600">
            Colonne attive
          </span>
          <span
            className="text-label-sm text-brandPrimary-600 cursor-pointer"
            onClick={() => {
              if (
                preferencesState.findAllResponse?.data[0].companyPreferences
                  .filter((col) => !alwaysVisibleCompany.includes(col.column))
                  .some((col) => col.visible)
              )
                dispatch(
                  updatePreferences({
                    data: {
                      owner: preferencesState.findAllResponse.data[0].owner,
                      companyPreferences:
                        preferencesState.findAllResponse?.data[0].companyPreferences.map(
                          (column) => ({
                            column: column.column,
                            hideable: column.hideable,
                            visible: alwaysVisibleCompany.includes(
                              column.column
                            )
                              ? true
                              : false,
                          })
                        ),
                      personPreferences:
                        preferencesState.findAllResponse.data[0]
                          .personPreferences,
                      targetListPreferences:
                        preferencesState.findAllResponse.data[0]
                          .targetListPreferences,
                    },
                    id: preferencesState.findAllResponse.data[0].id,
                  })
                );
              else
                dispatch(
                  updatePreferences({
                    data: {
                      owner: preferencesState.findAllResponse?.data[0].owner!,
                      companyPreferences:
                        preferencesState.findAllResponse?.data[0].companyPreferences.map(
                          (column) => ({
                            column: column.column,
                            visible: true,
                            hideable: column.hideable,
                          })
                        )!,
                      personPreferences:
                        preferencesState.findAllResponse?.data[0]
                          .personPreferences!,
                      targetListPreferences:
                        preferencesState.findAllResponse?.data[0]
                          .targetListPreferences!,
                    },
                    id: preferencesState.findAllResponse?.data[0].id!,
                  })
                );
            }}
          >
            {preferencesState.findAllResponse?.data[0].companyPreferences
              .filter((col) => !alwaysVisibleCompany.includes(col.column))
              .some((col) => col.visible)
              ? "Nascondi tutto"
              : "Visualizza tutto"}
          </span>
        </div>
        <Sorting
          key={"company-sortings"}
          items={
            preferencesState.findAllResponse?.data[0].companyPreferences.map(
              (item) => ({
                visible: item.visible,
                column: item.column,
                hideable: !alwaysVisibleCompany.includes(item.column),
              })
            )!
          }
          sortItems={(items) => {
            dispatch(
              updatePreferences({
                data: {
                  owner: preferencesState.findAllResponse?.data[0].owner!,
                  companyPreferences: items,
                  personPreferences:
                    preferencesState.findAllResponse?.data[0]
                      .personPreferences!,
                  targetListPreferences:
                    preferencesState.findAllResponse?.data[0]
                      .targetListPreferences!,
                },
                id: preferencesState.findAllResponse?.data[0].id!,
              })
            );
          }}
        />
      </div>
    </div>
  );
}
