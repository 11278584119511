import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { TargetListPageEnum } from "../../../targetList/dto";
import {
  findAllCompaniesForFilters,
  findAllProjectsForFilters,
  findAllTargetListForFilters,
} from "../../slice";
import {
  findAllPeople,
  setFindAllPeopleFiltersAtecos,
  setFindAllPeopleFiltersCategory,
  setFindAllPeopleFiltersCity,
  setFindAllPeopleFiltersCountryRegion,
  setFindAllPeopleFiltersDepartment,
  setFindAllPeopleFiltersEmployees,
  setFindAllPeopleFiltersName,
  setFindAllPeopleFiltersOutcome,
  setFindAllPeopleFiltersPrivacy,
  setFindAllPeopleFiltersProject,
  setFindAllPeopleFiltersRevenue,
  setFindAllPeopleFiltersRole,
  setFindAllPeopleFiltersSector,
  setFindAllPeopleFiltersSmartphone,
  setFindAllPeopleFiltersState,
  setFindAllPeopleFiltersTargetList,
  setFindAllPeopleFiltersZipCode,
} from "../slice";
import { AtecoFilter } from "./ateco";
import { CategoryFilter } from "./category";
import { CompanyFilter } from "./company";
import { DepartmentFilter } from "./department";
import { EmployeesNumberFilter } from "./employeesNumber";
import { OutcomeFilter } from "./outcome";
import { PrivacyFilter } from "./privacy";
import { ProjectFilter } from "./project";
import { RevenueFilter } from "./revenue";
import { RoleFilter } from "./role";
import { SectorFilter } from "./sector";
import { SmartphoneFilter } from "./smartphone";
import { StateFilter } from "./state";
import { TargetFilter } from "./target";

export function Filters() {
  const peopleState = useAppSelector((state) => state.people);
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(findAllCompaniesForFilters());
    dispatch(findAllTargetListForFilters());
    dispatch(findAllProjectsForFilters());
  }, []);

  const filters = Object.entries(peopleState.filters).filter(
    (entr) =>
      entr[0] !== "page" &&
      entr[0] !== "itemsPerPage" &&
      entr[0] !== "order" &&
      entr[0] !== "sort" &&
      entr[0] !== "personName" &&
      entr[0] !== "personEmail" &&
      entr[0] !== "hasCompany" &&
      entr[1] !== undefined &&
      entr[1].length !== 0 &&
      (window.location.pathname.includes("target") &&
      targetListsState.targetListPage !== TargetListPageEnum.DB
        ? entr[0] !== "project" && entr[0] !== "targetList"
        : true)
  ).length;

  return (
    <div className="bg-white border-r border-neutral-200  h-full">
      <div className="px-[24px] h-16 flex items-center justify-between">
        <span className="text-text-md text-neutral-800 font-bold">
          {"Filtri " + (filters === 0 ? "" : "(" + filters + ")")}
        </span>
        <div className="flex items-center">
          <Button
            size={"sm"}
            iconPosition={"off"}
            variant={"link"}
            color={"blue"}
            label="Pulisci tutti"
            onClick={() => {
              dispatch(setFindAllPeopleFiltersName([]));
              dispatch(setFindAllPeopleFiltersRevenue(undefined));
              dispatch(setFindAllPeopleFiltersEmployees(undefined));
              dispatch(setFindAllPeopleFiltersAtecos([]));
              dispatch(setFindAllPeopleFiltersSector([]));
              dispatch(setFindAllPeopleFiltersCategory([]));
              dispatch(setFindAllPeopleFiltersDepartment([]));
              dispatch(setFindAllPeopleFiltersRole([]));
              dispatch(setFindAllPeopleFiltersState(undefined));
              dispatch(setFindAllPeopleFiltersCity([]));
              dispatch(setFindAllPeopleFiltersCountryRegion([]));
              dispatch(setFindAllPeopleFiltersSmartphone(undefined));
              dispatch(setFindAllPeopleFiltersPrivacy(undefined));
              dispatch(setFindAllPeopleFiltersZipCode([]));
              dispatch(
                setFindAllPeopleFiltersTargetList(
                  window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                    ? [
                        {
                          label: targetListsState.findTargetListResponse?.name!,
                          value: targetListsState.findTargetListResponse?.id!,
                        },
                      ]
                    : []
                )
              );
              dispatch(
                setFindAllPeopleFiltersProject(
                  window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                    ? [
                        {
                          label:
                            targetListsState.findTargetListResponse?.project
                              .name!,
                          value:
                            targetListsState.findTargetListResponse?.project
                              .id!,
                        },
                      ]
                    : []
                )
              );
              dispatch(setFindAllPeopleFiltersOutcome([]));
              dispatch(
                findAllPeople({
                  atecos: [],
                  category: [],
                  department: [],
                  employees: undefined,
                  itemsPerPage: 10,
                  name: [],
                  order: true,
                  page: 0,
                  privacy: undefined,
                  revenue: undefined,
                  role: [],
                  sector: [],
                  city: [],
                  smartphone: undefined,
                  sort: undefined,
                  state: undefined,
                  outcome: [],
                  countryRegion: [],
                  zipCode: [],
                  targetList:
                    window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                      ? [
                          {
                            label:
                              targetListsState.findTargetListResponse?.name!,
                            value: targetListsState.findTargetListResponse?.id!,
                          },
                        ]
                      : [],
                  project:
                    window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                      ? [
                          {
                            label:
                              targetListsState.findTargetListResponse?.project
                                .name!,
                            value:
                              targetListsState.findTargetListResponse?.project
                                .id!,
                          },
                        ]
                      : [],
                })
              );
            }}
          />
        </div>
      </div>
      <div className=" w-full">
        <CompanyFilter />
        <RevenueFilter />
        <OutcomeFilter />
        <EmployeesNumberFilter />
        {(!window.location.pathname.includes("target") ||
          (window.location.pathname.includes("target") &&
            targetListsState.targetListPage === TargetListPageEnum.DB)) && (
          <>
            <ProjectFilter />
            <TargetFilter />
          </>
        )}
        <AtecoFilter />
        <SectorFilter />
        <CategoryFilter />
        <DepartmentFilter />
        <RoleFilter />
        <SmartphoneFilter />
        <PrivacyFilter />
        <StateFilter />
      </div>
    </div>
  );
}
