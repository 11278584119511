import { NumericFormat } from "react-number-format";
import { roleDepartment } from "../../../lib/preferences/dto";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { groupBy } from "../../../lib/utils";
import {
  setNewTargetListCategories,
  setNewTargetListCommercialIds,
  setNewTargetListDepartments,
  setNewTargetListName,
  setNewTargetListRoles,
  setNewTargetListSectors,
} from "../../targetList/slice";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";

export function Form() {
  const projectsState = useAppSelector((state) => state.projects);
  const targetListState = useAppSelector((state) => state.targetList);
  const preferencesState = useAppSelector((state) => state.preferences);
  const dispatch = useAppDispatch();

  const signMap = new Map<"=" | "<" | ">" | "><", string>([
    ["=", "Uguale a"],
    ["<", "Minore di"],
    [">", "Maggiore di"],
    ["><", "Compreso tra"],
  ]);

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex gap-4">
        <Input
          label="Nome del Target"
          placeholder="Nome lista target"
          disabled
          error={targetListState.validateNewTargetList.errors.name}
          supportingText={
            targetListState.validateNewTargetList.errors.name
              ? "Inserire il nome della lista target."
              : ""
          }
          value={targetListState.findTargetListResponse?.name}
          onChangeText={(e) => dispatch(setNewTargetListName(e))}
        />
        <SelectCustom
          isMulti
          disabled
          error={targetListState.validateNewTargetList.errors.departments}
          errorLabel="Selezionare almeno un dipartimento."
          placeholder={"Seleziona i reparti"}
          options={groupBy(roleDepartment, "department").map((department) => ({
            label: department.key,
            value: department.key,
          }))}
          label="Seleziona i reparti da contattare"
          defaultValue={targetListState.findTargetListResponse?.departments}
          onChange={(e) => dispatch(setNewTargetListDepartments(e))}
        />
        <SelectCustom
          isMulti
          error={targetListState.validateNewTargetList.errors.roles}
          errorLabel="Selezionare almeno un ruolo."
          placeholder={"Seleziona i ruoli"}
          disabled
          options={groupBy(
            roleDepartment.filter((row) =>
              targetListState.findTargetListResponse?.departments.includes(
                row.department
              )
            ),
            "role"
          ).map((department) => ({
            label: department.key,
            value: department.key,
          }))}
          label="Ruolo"
          defaultValue={targetListState.findTargetListResponse?.roles}
          onChange={(e) => dispatch(setNewTargetListRoles(e))}
        />
      </div>
      <div className="flex gap-4">
        <SelectCustom
          isMulti
          disabled
          placeholder={"Seleziona i settori"}
          error={targetListState.validateNewTargetList.errors.sectors}
          errorLabel="Selezionare almeno un settore."
          options={groupBy(
            preferencesState.findAllAtecosResponse,
            "sector"
          ).map((sector) => ({ label: sector.key, value: sector.key }))}
          label="Settori"
          defaultValue={targetListState.findTargetListResponse?.sectors}
          onChange={(e) => dispatch(setNewTargetListSectors(e))}
        />
        <SelectCustom
          isMulti
          placeholder={"Seleziona le categorie"}
          error={targetListState.validateNewTargetList.errors.categories}
          disabled
          errorLabel="Selezionare almeno una categoria."
          options={groupBy(
            preferencesState.findAllAtecosResponse.filter((row) =>
              targetListState.findTargetListResponse?.sectors.includes(
                row.sector
              )
            ),
            "category"
          ).map((category) => ({ label: category.key, value: category.key }))}
          label="Categorie"
          defaultValue={targetListState.findTargetListResponse?.categories}
          onChange={(e) => dispatch(setNewTargetListCategories(e))}
        />
        <SelectCustom
          isMulti
          disabled
          placeholder={"Seleziona i commerciali"}
          error={targetListState.validateNewTargetList.errors.commercials}
          errorLabel="Selezionare almeno un commerciale."
          options={
            projectsState.findProjectResponse?.commercials.map(
              (commercial) => ({
                label: commercial.name + " " + commercial.surname,
                value: commercial.id,
              })
            )!
          }
          label="Assegna ai commerciali"
          defaultValue={targetListState.findTargetListResponse?.commercials.map(
            (commercial) => commercial.id
          )}
          onChange={(e) => dispatch(setNewTargetListCommercialIds(e))}
        />
      </div>
      <div className="flex gap-4 w-full">
        <div style={{ width: "calc((100% - 32px) / 3)" }}>
          <SelectCustom
            isClearable
            disabled
            placeholder={"Inserisci range del fatturato"}
            options={[
              { label: "Uguale a", value: "=" },
              { label: "Minore di", value: "<" },
              { label: "Maggiore di", value: ">" },
              { label: "Compreso tra", value: "><" },
            ]}
            label="Fatturato"
            defaultValue={
              targetListState.findTargetListResponse?.revenue.split(":")[0]
            }
            error={targetListState.validateNewTargetList.errors.revenueSign}
            errorLabel="Selezionare il tipo di fatturato."
          />
        </div>
        {targetListState.findTargetListResponse?.revenue.split(":")[0] !==
          "undefined" && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              prefix="€ "
              value={
                targetListState.findTargetListResponse?.revenue.split(":")[1]
              }
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              disabled
              fixedDecimalScale
              label={signMap.get(
                targetListState.findTargetListResponse?.revenue.split(
                  ":"
                )[0] as "=" | "<" | ">" | "><"
              )}
              error={targetListState.validateNewTargetList.errors.revenueFirst}
              supportingText={
                targetListState.validateNewTargetList.errors.revenueFirst
                  ? "Inserire il fatturato"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
        {targetListState.findTargetListResponse?.revenue.split(":")[0] ===
          "><" && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              prefix="€ "
              value={
                targetListState.findTargetListResponse?.revenue.split(":")[2]
              }
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              disabled
              fixedDecimalScale
              label={signMap.get(
                targetListState.findTargetListResponse?.revenue.split(
                  ":"
                )[0] as "=" | "<" | ">" | "><"
              )}
              error={targetListState.validateNewTargetList.errors.revenueSecond}
              supportingText={
                targetListState.validateNewTargetList.errors.revenueSecond
                  ? "Inserire il fatturato"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
      </div>
      <div className="flex gap-4 w-full">
        <div style={{ width: "calc((100% - 32px) / 3)" }}>
          <SelectCustom
            isClearable
            disabled
            placeholder={"Inserisci range del numero di dipendenti"}
            options={[
              { label: "Uguale a", value: "=" },
              { label: "Minore di", value: "<" },
              { label: "Maggiore di", value: ">" },
              { label: "Compreso tra", value: "><" },
            ]}
            label="Numero di dipendenti"
            error={targetListState.validateNewTargetList.errors.employeesSign}
            errorLabel="Selezionare il tipo di numero dipendenti."
            defaultValue={
              targetListState.findTargetListResponse?.employeesNumber.split(
                ":"
              )[0]
            }
            // onChange={e => setSignEmployeesNumber(e as "=" | "<" | ">" | "><" | undefined)}
          />
        </div>
        {targetListState.findTargetListResponse?.employeesNumber.split(
          ":"
        )[0] !== "undefined" && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              value={
                targetListState.findTargetListResponse?.employeesNumber.split(
                  ":"
                )[1]
              }
              thousandSeparator="."
              disabled
              decimalSeparator=","
              label={signMap.get(
                targetListState.findTargetListResponse?.employeesNumber.split(
                  ":"
                )[0] as "=" | "<" | ">" | "><"
              )}
              error={
                targetListState.validateNewTargetList.errors.employeesFirst
              }
              supportingText={
                targetListState.validateNewTargetList.errors.employeesFirst
                  ? "Inserire il numero di dipendenti"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
        {targetListState.findTargetListResponse?.employeesNumber.split(
          ":"
        )[0] === "><" && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              value={
                targetListState.findTargetListResponse?.employeesNumber.split(
                  ":"
                )[2]
              }
              thousandSeparator="."
              decimalSeparator=","
              disabled
              label={signMap.get(
                targetListState.findTargetListResponse?.employeesNumber.split(
                  ":"
                )[0] as "=" | "<" | ">" | "><"
              )}
              error={
                targetListState.validateNewTargetList.errors.employeesSecond
              }
              supportingText={
                targetListState.validateNewTargetList.errors.employeesSecond
                  ? "Inserire il numero di dipendenti"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
      </div>
    </div>
  );
}
