import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../lib/types";
import { ImportCSVResponseDTO } from "../dto";
import { PersonDTO } from "../people/dto";
import { NewPeopleService } from "../people/service";
import {
  CompanyDTO,
  EditCompanyRequest,
  FindAllCompaniesFilters,
  FindAllCompaniesResponse,
} from "./dto";
import { NewCompaniesService } from "./service";

interface CompaniesState {
  findAllStatus: PromiseStatuses;
  findAllResponse?: FindAllCompaniesResponse;
  filters: FindAllCompaniesFilters;
  deleteCompanyStatus: PromiseStatuses;
  idToDelete?: string;
  findCompanyResponse?: CompanyDTO;
  findCompanyStatus: PromiseStatuses;
  selectedCompany?: string;
  selectedCompanyNameForEmployees?: string;
  editCompanyRequest: EditCompanyRequest;
  editCompanyStatus: PromiseStatuses;
  findEmployeesResponse: PersonDTO[];
  findEmployeesStatus: PromiseStatuses;
  importCSVStatus: PromiseStatuses;
  importCSVResponse?: ImportCSVResponseDTO;
}

const initialState: CompaniesState = {
  findAllStatus: "idle",
  filters: {
    order: true,
    sort: "name",
    itemsPerPage: 10,
    page: 0,
    name: [],
    atecos: [],
    sector: [],
    category: [],
    department: [],
    role: [],
    revenue: undefined,
    employees: undefined,
    smartphone: undefined,
    privacy: undefined,
    state: undefined,
    city: [],
    countryRegion: [],
    zipCode: [],
    targetList: [],
    outcome: [],
    project: [],
  },
  deleteCompanyStatus: "idle",
  findCompanyStatus: "idle",
  editCompanyRequest: {
    name: "",
    revenue: 110,
    phone: "",
    email: "",
    address: "",
    vat: "",
    employeesNumber: 0,
    website: "",
    active: false,
  },
  editCompanyStatus: "idle",
  findEmployeesStatus: "idle",
  findEmployeesResponse: [],
  importCSVStatus: "idle",
};

export const findAllCompanies = createAsyncThunk(
  "companies/findAllCompanies",
  async (
    request: FindAllCompaniesFilters
  ): Promise<FindAllCompaniesResponse> => {
    const CompanyService = NewCompaniesService();

    return CompanyService.findAllCompanies(request);
  }
);

export const deleteCompany = createAsyncThunk(
  "companies/deleteCompany",
  async (id: string): Promise<void> => {
    const CompanyService = NewCompaniesService();

    return CompanyService.deleteCompany(id);
  }
);

export const findCompany = createAsyncThunk(
  "companies/findCompany",
  async (id: string): Promise<CompanyDTO> => {
    const CompanyService = NewCompaniesService();

    return CompanyService.findCompanyById(id);
  }
);

export const editCompany = createAsyncThunk(
  "companies/editCompany",
  async (request: { id: string; data: EditCompanyRequest }): Promise<void> => {
    const CompanyService = NewCompaniesService();

    return CompanyService.editCompany(request.id, request.data);
  }
);

export const findEmployees = createAsyncThunk(
  "companies/findEmployees",
  async (ids: string[], thunkApi): Promise<PersonDTO[]> => {
    let promises: Promise<PersonDTO>[] = [];
    promises = ids.map(async (id) => {
      const personService = NewPeopleService();

      return personService.findPersonById(id);
    });
    return Promise.all(promises);
  }
);

export const importCSV = createAsyncThunk(
  "companies/importCSV",
  async (request: FormData): Promise<ImportCSVResponseDTO> => {
    const companyService = NewCompaniesService();

    return companyService.importContact(request);
  }
);

const companiesSlice = createSlice({
  name: "companies/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(findAllCompanies.pending, (state) => {
        state.findAllStatus = "loading";
      })
      .addCase(findAllCompanies.fulfilled, (state, action) => {
        state.findAllStatus = "successfully";
        state.findAllResponse = action.payload;
      })
      .addCase(findAllCompanies.rejected, (state) => {
        state.findAllStatus = "failed";
      })
      .addCase(deleteCompany.pending, (state) => {
        state.deleteCompanyStatus = "loading";
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.deleteCompanyStatus = "successfully";
      })
      .addCase(deleteCompany.rejected, (state) => {
        state.deleteCompanyStatus = "failed";
      })
      .addCase(findCompany.pending, (state) => {
        state.findCompanyStatus = "loading";
      })
      .addCase(findCompany.fulfilled, (state, action) => {
        state.findCompanyStatus = "successfully";
        state.findCompanyResponse = action.payload;
      })
      .addCase(findCompany.rejected, (state) => {
        state.findCompanyStatus = "failed";
      })
      .addCase(editCompany.pending, (state) => {
        state.editCompanyStatus = "loading";
      })
      .addCase(editCompany.fulfilled, (state, action) => {
        state.editCompanyStatus = "successfully";
      })
      .addCase(editCompany.rejected, (state) => {
        state.editCompanyStatus = "failed";
      })
      .addCase(findEmployees.pending, (state) => {
        state.findEmployeesStatus = "loading";
      })
      .addCase(findEmployees.fulfilled, (state, action) => {
        state.findEmployeesStatus = "successfully";
        state.findEmployeesResponse = action.payload;
      })
      .addCase(findEmployees.rejected, (state) => {
        state.findEmployeesStatus = "failed";
      })
      .addCase(importCSV.pending, (state) => {
        state.importCSVStatus = "loading";
      })
      .addCase(importCSV.fulfilled, (state, action) => {
        state.importCSVStatus = "successfully";
        state.importCSVResponse = action.payload;
      })
      .addCase(importCSV.rejected, (state) => {
        state.importCSVStatus = "failed";
      });
  },
  reducers: {
    setFindAllCompaniesStatus: (state, action) => {
      state.findAllStatus = action.payload;
    },
    setFindAllCompaniesFiltersOrder: (state, action) => {
      state.filters.order = action.payload;
    },
    setFindAllCompaniesFiltersSort: (state, action) => {
      state.filters.sort = action.payload;
    },
    setFindAllCompaniesFiltersItemsPerPage: (state, action) => {
      state.filters.itemsPerPage = action.payload;
    },
    setFindAllCompaniesFiltersPage: (state, action) => {
      state.filters.page = action.payload;
    },
    setDeleteCompanyStatus: (state, action) => {
      state.deleteCompanyStatus = action.payload;
    },
    setIdToDeleteCompany: (state, action) => {
      state.idToDelete = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setEditCompanyVat: (state, action) => {
      state.editCompanyRequest.vat = action.payload;
    },
    setEditCompanySector: (state, action) => {
      state.editCompanyRequest.sector = action.payload;
    },
    setEditCompanyCategory: (state, action) => {
      state.editCompanyRequest.category = action.payload;
    },
    setEditCompanyRevenue: (state, action) => {
      state.editCompanyRequest.revenue = action.payload;
    },
    setEditCompanyAtecoCode: (state, action) => {
      state.editCompanyRequest.atecoCode = action.payload;
    },
    setEditCompanyAddress: (state, action) => {
      state.editCompanyRequest.address = action.payload;
    },
    setEditCompanyRegion: (state, action) => {
      state.editCompanyRequest.countryRegion = action.payload;
    },
    setEditCompanyCity: (state, action) => {
      state.editCompanyRequest.city = action.payload;
    },
    setEditCompanyProvince: (state, action) => {
      state.editCompanyRequest.province = action.payload;
    },
    setEditCompanyCap: (state, action) => {
      state.editCompanyRequest.zipCode = action.payload;
    },
    setEditCompanyState: (state, action) => {
      state.editCompanyRequest.state = action.payload;
    },
    setEditCompanyName: (state, action) => {
      state.editCompanyRequest.name = action.payload;
    },
    setEditCompanyWebSite: (state, action) => {
      state.editCompanyRequest.website = action.payload;
    },
    setEditCompanyEmail: (state, action) => {
      state.editCompanyRequest.email = action.payload;
    },
    setEditCompanyPhone: (state, action) => {
      state.editCompanyRequest.phone = action.payload;
    },
    setEditCompanyEmployeesNumber: (state, action) => {
      state.editCompanyRequest.employeesNumber = action.payload;
    },
    setEditCompanyStatus: (state, action) => {
      state.editCompanyStatus = action.payload;
    },
    setFindAllCompaniesFiltersName: (state, action) => {
      state.filters.name = action.payload;
    },
    setFindAllCompaniesFiltersAtecos: (state, action) => {
      state.filters.atecos = action.payload;
    },
    setFindAllCompaniesFiltersRevenue: (state, action) => {
      state.filters.revenue = action.payload;
    },
    setFindAllCompaniesFiltersEmployees: (state, action) => {
      state.filters.employees = action.payload;
    },
    setFindAllCompaniesFiltersSector: (state, action) => {
      state.filters.sector = action.payload;
    },
    setFindAllCompaniesFiltersCategory: (state, action) => {
      state.filters.category = action.payload;
    },
    setFindAllCompaniesFiltersDepartment: (state, action) => {
      state.filters.department = action.payload;
    },
    setFindAllCompaniesFiltersRole: (state, action) => {
      state.filters.role = action.payload;
    },
    setFindAllCompaniesFiltersSmartphone: (state, action) => {
      state.filters.smartphone = action.payload;
    },
    setFindAllCompaniesFiltersPrivacy: (state, action) => {
      state.filters.privacy = action.payload;
    },
    setFindAllCompaniesFiltersState: (state, action) => {
      state.filters.state = action.payload;
    },
    setFindAllCompaniesFiltersCity: (state, action) => {
      state.filters.city = action.payload;
    },
    setFindAllCompaniesFiltersCountryRegion: (state, action) => {
      state.filters.countryRegion = action.payload;
    },
    setFindAllCompaniesFiltersZipCode: (state, action) => {
      state.filters.zipCode = action.payload;
    },
    setFindAllCompaniesFiltersTargetList: (state, action) => {
      state.filters.targetList = action.payload;
    },
    setFindAllCompaniesFiltersProject: (state, action) => {
      state.filters.project = action.payload;
    },
    setFindAllCompaniesFiltersOutcome: (state, action) => {
      state.filters.outcome = action.payload;
    },
    setFindEmployeesStatus: (state, action) => {
      state.findEmployeesStatus = action.payload;
    },
    setFindEmployeesResponse: (state, action) => {
      state.findEmployeesResponse = action.payload;
    },
    setSelectedCompanyNameForEmployees: (state, action) => {
      state.selectedCompanyNameForEmployees = action.payload;
    },
    setImportCSVStatus: (state, action) => {
      state.importCSVStatus = action.payload;
    },
    setImportCSVSResponse: (state, action) => {
      state.importCSVResponse = action.payload;
    },
  },
});

export const {
  setFindAllCompaniesStatus,
  setFindAllCompaniesFiltersItemsPerPage,
  setFindAllCompaniesFiltersOrder,
  setFindAllCompaniesFiltersPage,
  setFindAllCompaniesFiltersSort,
  setDeleteCompanyStatus,
  setIdToDeleteCompany,
  setSelectedCompany,
  setEditCompanyAddress,
  setEditCompanyAtecoCode,
  setEditCompanyCap,
  setEditCompanyCategory,
  setEditCompanyCity,
  setEditCompanyEmail,
  setEditCompanyName,
  setEditCompanyPhone,
  setEditCompanyProvince,
  setEditCompanyRegion,
  setEditCompanySector,
  setEditCompanyState,
  setEditCompanyVat,
  setEditCompanyWebSite,
  setEditCompanyEmployeesNumber,
  setEditCompanyStatus,
  setFindAllCompaniesFiltersAtecos,
  setFindAllCompaniesFiltersCategory,
  setFindAllCompaniesFiltersDepartment,
  setFindAllCompaniesFiltersEmployees,
  setFindAllCompaniesFiltersName,
  setFindAllCompaniesFiltersPrivacy,
  setFindAllCompaniesFiltersRevenue,
  setFindAllCompaniesFiltersRole,
  setFindAllCompaniesFiltersSector,
  setFindAllCompaniesFiltersSmartphone,
  setFindAllCompaniesFiltersCity,
  setFindAllCompaniesFiltersCountryRegion,
  setFindAllCompaniesFiltersState,
  setFindEmployeesStatus,
  setSelectedCompanyNameForEmployees,
  setFindEmployeesResponse,
  setFindAllCompaniesFiltersZipCode,
  setImportCSVSResponse,
  setImportCSVStatus,
  setEditCompanyRevenue,
  setFindAllCompaniesFiltersTargetList,
  setFindAllCompaniesFiltersOutcome,
  setFindAllCompaniesFiltersProject,
} = companiesSlice.actions;

export default companiesSlice.reducer;
