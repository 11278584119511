import { encode } from "base64-arraybuffer";

export async function toBase64(file: File): Promise<string> {
  const bytes = new Uint8Array(await file.arrayBuffer());

  let binary = "";

  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export const breadcrumbsLabel = new Map<string, string>([
  ["dashboard", "Dashboard"],
  ["database", "Database"],
  ["target-list", "Liste Target"],
  ["projects", "Progetti"],
  ["users", "Utenti"],
  ["pipeline", "Pipeline"],
  ["calendar", "Calendario"],
  ["report", "Report"],
  ["settings", "Impostazioni"],
]);

type Grouped<T> = { [key: string]: T[] };

export function groupBy<T>(
  array: T[],
  key: keyof T
): { key: string; values: T[] }[] {
  const groupedObject = array.reduce((result, currentValue) => {
    const groupKey = currentValue[key] as unknown as string;
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(currentValue);
    return result;
  }, {} as Grouped<T>);

  return groupedObjectToArray(groupedObject);
}

function groupedObjectToArray<T>(
  groupedObject: Grouped<T>
): { key: string; values: T[] }[] {
  return Object.keys(groupedObject).map((key) => ({
    key,
    values: groupedObject[key],
  }));
}

export const generatePagination = (currentPage: number, totalPages: number) => {
  if (totalPages <= 7) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }
  if (currentPage <= 3) {
    return [1, 2, 3, "...", totalPages - 1, totalPages];
  }
  if (currentPage >= totalPages - 2) {
    return [1, 2, "...", totalPages - 2, totalPages - 1, totalPages];
  }
  return [
    1,
    "...",
    currentPage - 1,
    currentPage,
    currentPage + 1,
    "...",
    totalPages,
  ];
};

export const encodeBase64 = (file: ArrayBuffer) => {
  return "data:" + file + ";base64," + encode(file);
};

export function formatFileSize(bytes: number): string {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Bytes";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
}

export function estimateUploadTime(
  fileSizeBytes: number,
  uploadSpeedBps: number
) {
  const fileSizeBits = fileSizeBytes * 8;
  const timeInSeconds = fileSizeBits / uploadSpeedBps;
  return formatTime(timeInSeconds);
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  if (minutes > 0) {
    return `${minutes} min ${remainingSeconds} sec`;
  }
  return `${remainingSeconds} sec`;
};
