import { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { TargetListPageEnum } from "../../../targetList/dto";
import { setTargetListPage } from "../../../targetList/slice";
import { ImportFromCSV } from "./csv";
import { ImportModalPeople } from "./csv/modal";
import { ImportFromDatabase } from "./database";
import { ImportType } from "./importType";
import { TargetListPeopleList } from "./list/index";
import { ImportFromTargetList } from "./targetList";

export function TargetListPeople() {
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setTargetListPage(
        Object.keys(
          targetListsState.findTargetListResponse?.companyContacts ?? {}
        ).length === 0
          ? TargetListPageEnum.ADD
          : TargetListPageEnum.LIST
      )
    );
  }, []);

  const pageMap = new Map<TargetListPageEnum, ReactElement>([
    [TargetListPageEnum.ADD, <ImportType />],
    [TargetListPageEnum.CSV, <ImportFromCSV />],
    [TargetListPageEnum.DB, <ImportFromDatabase />],
    [TargetListPageEnum.TL, <ImportFromTargetList />],
    [TargetListPageEnum.LIST, <TargetListPeopleList />],
  ]);

  return (
    <>
      {pageMap.get(targetListsState.targetListPage)}
      <ImportModalPeople />
    </>
  );
}
