import {
  FindAllUserResponse,
  FindAllUsersFilters,
  UserDTO,
  UserRoleUpdateDTO,
} from "./dto";
import { UsersServiceImpl } from "./serviceImpl";

export interface UsersService {
  findAllUsers(filters: FindAllUsersFilters): Promise<FindAllUserResponse>;
  findUserById(id: string): Promise<UserDTO>;
  editUser(id: string, data: FormData): Promise<void>;
  createUser(data: FormData): Promise<void>;
  deleteUser(id: string): Promise<void>;
  updateRole(id: string, data: UserRoleUpdateDTO): Promise<void>;
}

export function NewUsersService(): UsersService {
  return new UsersServiceImpl();
}
