import React, { useEffect, useState, useCallback } from "react";
import { NewParticularitiesService } from "../../../particularities/service";
import { SelectCustomAsync } from "../../../../ui/molecules/asyncSelect";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { setNewTargetListParticularity } from "../../../targetList/slice";
import { doesNotMatch } from "assert";
import { FindAllParticularitiesResponse } from "../../../particularities/dto";

interface OptionType {
  value: string;
  label: string;
}

const SelectParticularities: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const targetListState = useAppSelector((state) => state.targetList);
  const particularityState = useAppSelector((state) => state.particularities);

  const loadOptions = useCallback(
    async (inputValue: string) => {
      if (!hasMore) return options;

      const response = await NewParticularitiesService().findAllParticularities(
        {
          itemsPerPage: 5,
          page: page,
          name: inputValue ?? "",
        }
      );

      const newOptions = response.data.map((data) => ({
        label: data.name,
        value: data.id,
      }));

      if (newOptions.length < 5) {
        setHasMore(false);
      }

      setPage((prevPage) => prevPage + 1);

      const updatedOptions = [...options, ...newOptions];
      setOptions(updatedOptions);

      return updatedOptions;
    },
    [page, hasMore, options]
  );

  useEffect(() => {
    const loadInitialOptions = async () => {
      const initialOptions =
        await NewParticularitiesService().findAllParticularities({
          itemsPerPage: 5,
          page: page,
          name: "",
        });

      const optionsData = initialOptions.data.map((data) => ({
        label: data.name,
        value: data.id,
      }));

      setOptions(optionsData);
      setPage(1);

      if (optionsData.length < 5) {
        setHasMore(false);
      }
    };

    loadInitialOptions();
  }, []);

  useEffect(() => {
    if (particularityState.createParticularityStatus === "successfully") {
      const loadInitialOptions =
        async (): Promise<FindAllParticularitiesResponse> => {
          const initialOptions =
            await NewParticularitiesService().findAllParticularities({
              itemsPerPage: 5,
              page: 0,
              name: "",
            });

          const optionsData = initialOptions.data.map((data) => ({
            label: data.name,
            value: data.id,
          }));

          setOptions(optionsData);
          setPage(1);

          if (optionsData.length < 5) {
            setHasMore(false);
          }

          return initialOptions;
        };

      loadInitialOptions().then((e) => {
        dispatch(
          setNewTargetListParticularity(
            particularityState.createParticularityResponse
          )
        );
      });
    }
  }, [particularityState.createParticularityStatus]);

  const handleScroll = (event: WheelEvent | TouchEvent) => {
    const target = event.target as HTMLElement;

    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      loadOptions("");
    }
  };

  return (
    <SelectCustomAsync
      label="Particolarità"
      loadOptions={loadOptions}
      defaultOptions={options}
      defaultValue={
        targetListState.newTargetListRequest.particularityId &&
        options.find(
          (opt) =>
            opt.value === targetListState.newTargetListRequest.particularityId
        )?.label
          ? {
              value: targetListState.newTargetListRequest.particularityId,
              label: options.find(
                (opt) =>
                  opt.value ===
                  targetListState.newTargetListRequest.particularityId
              )?.label!,
            }
          : undefined
      }
      isClearable
      onChange={(e) => {
        dispatch(
          //@ts-ignore
          setNewTargetListParticularity(e === null ? undefined : e.value)
        );
      }}
      placeholder="Seleziona una particolarità..."
      error={false}
      errorLabel="Errore durante la selezione"
      onMenuScrollToBottom={handleScroll}
    />
  );
};

export default SelectParticularities;
