import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { TargetListPageEnum } from "../../../targetList/dto";
import {
  findAllCompaniesForFilters,
  findAllProjectsForFilters,
  findAllTargetListForFilters,
} from "../../slice";
import {
  findAllCompanies,
  setFindAllCompaniesFiltersAtecos,
  setFindAllCompaniesFiltersCategory,
  setFindAllCompaniesFiltersCity,
  setFindAllCompaniesFiltersCountryRegion,
  setFindAllCompaniesFiltersDepartment,
  setFindAllCompaniesFiltersEmployees,
  setFindAllCompaniesFiltersName,
  setFindAllCompaniesFiltersOutcome,
  setFindAllCompaniesFiltersPrivacy,
  setFindAllCompaniesFiltersProject,
  setFindAllCompaniesFiltersRevenue,
  setFindAllCompaniesFiltersRole,
  setFindAllCompaniesFiltersSector,
  setFindAllCompaniesFiltersSmartphone,
  setFindAllCompaniesFiltersState,
  setFindAllCompaniesFiltersTargetList,
  setFindAllCompaniesFiltersZipCode,
} from "../slice";
import { AtecoFilter } from "./ateco";
import { CategoryFilter } from "./category";
import { CompanyFilter } from "./company";
import { DepartmentFilter } from "./department";
import { EmployeesNumberFilter } from "./employeesNumber";
import { OutcomeFilter } from "./outcome";
import { PrivacyFilter } from "./privacy";
import { ProjectFilter } from "./project";
import { RevenueFilter } from "./revenue";
import { RoleFilter } from "./role";
import { SectorFilter } from "./sector";
import { SmartphoneFilter } from "./smartphone";
import { StateFilter } from "./state";
import { TargetFilter } from "./target";

export function Filters() {
  const companiesState = useAppSelector((state) => state.companies);
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(findAllCompaniesForFilters());
    dispatch(findAllTargetListForFilters());
    dispatch(findAllProjectsForFilters());
  }, []);

  const filters = Object.entries(companiesState.filters).filter(
    (entr) =>
      entr[0] !== "page" &&
      entr[0] !== "itemsPerPage" &&
      entr[0] !== "order" &&
      entr[0] !== "sort" &&
      entr[1] !== undefined &&
      entr[1].length !== 0 &&
      (window.location.pathname.includes("target") &&
      targetListsState.targetListPage !== TargetListPageEnum.DB
        ? entr[0] !== "project" && entr[0] !== "targetList"
        : true)
  ).length;

  return (
    <div className="bg-white border-r border-neutral-200 h-full overflow-auto">
      <div className="px-[24px] py-[12px] flex items-center justify-between">
        <span className="text-text-md text-neutral-800 font-bold">
          {"Filtri " + (filters === 0 ? "" : "(" + filters + ")")}
        </span>
        <div className="flex items-center">
          <Button
            size={"sm"}
            iconPosition={"off"}
            variant={"link"}
            color={"blue"}
            label="Pulisci tutti"
            onClick={() => {
              dispatch(setFindAllCompaniesFiltersName([]));
              dispatch(setFindAllCompaniesFiltersRevenue(undefined));
              dispatch(setFindAllCompaniesFiltersEmployees(undefined));
              dispatch(setFindAllCompaniesFiltersAtecos([]));
              dispatch(setFindAllCompaniesFiltersSector([]));
              dispatch(setFindAllCompaniesFiltersCategory([]));
              dispatch(setFindAllCompaniesFiltersDepartment([]));
              dispatch(setFindAllCompaniesFiltersRole([]));
              dispatch(setFindAllCompaniesFiltersSmartphone(undefined));
              dispatch(setFindAllCompaniesFiltersPrivacy(undefined));
              dispatch(setFindAllCompaniesFiltersState(undefined));
              dispatch(setFindAllCompaniesFiltersCountryRegion([]));
              dispatch(setFindAllCompaniesFiltersCity([]));
              dispatch(setFindAllCompaniesFiltersZipCode([]));
              dispatch(
                setFindAllCompaniesFiltersProject(
                  window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                    ? [
                        {
                          label:
                            targetListsState.findTargetListResponse?.project
                              .name!,
                          value:
                            targetListsState.findTargetListResponse?.project
                              .id!,
                        },
                      ]
                    : []
                )
              );
              dispatch(setFindAllCompaniesFiltersOutcome([]));
              dispatch(
                setFindAllCompaniesFiltersTargetList(
                  window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                    ? [
                        {
                          label: targetListsState.findTargetListResponse?.name!,
                          value: targetListsState.findTargetListResponse?.id!,
                        },
                      ]
                    : []
                )
              );
              dispatch(
                findAllCompanies({
                  atecos: [],
                  category: [],
                  department: [],
                  employees: undefined,
                  itemsPerPage: 10,
                  name: [],
                  order: true,
                  page: 0,
                  privacy: undefined,
                  revenue: undefined,
                  role: [],
                  sector: [],
                  smartphone: undefined,
                  sort: undefined,
                  state: undefined,
                  city: [],
                  countryRegion: [],
                  project:
                    window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                      ? [
                          {
                            label:
                              targetListsState.findTargetListResponse?.project
                                .name!,
                            value:
                              targetListsState.findTargetListResponse?.project
                                .id!,
                          },
                        ]
                      : [],
                  outcome: [],
                  zipCode: [],
                  targetList:
                    window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                      ? [
                          {
                            label:
                              targetListsState.findTargetListResponse?.name!,
                            value: targetListsState.findTargetListResponse?.id!,
                          },
                        ]
                      : [],
                })
              );
            }}
          />
        </div>
      </div>
      <CompanyFilter />
      <RevenueFilter />
      <OutcomeFilter />
      <EmployeesNumberFilter />
      {(!window.location.pathname.includes("target") ||
        (window.location.pathname.includes("target") &&
          targetListsState.targetListPage === TargetListPageEnum.DB)) && (
        <>
          <ProjectFilter />
          <TargetFilter />
        </>
      )}
      <AtecoFilter />
      <SectorFilter />
      <CategoryFilter />
      <DepartmentFilter />
      <RoleFilter />
      <SmartphoneFilter />
      <PrivacyFilter />
      <StateFilter />
    </div>
  );
}
