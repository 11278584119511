import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { PlusIcon } from "../../../ui/icons/plus";
import Button from "../../../ui/molecules/button";
import { Spinner } from "../../../ui/molecules/spinner";
import { Banner } from "../../../ui/organisms/banner";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import {
  findAllCompanies,
  setFindAllCompaniesFiltersPage,
  setFindAllCompaniesFiltersTargetList,
} from "../../database/companies/slice";
import {
  findAllPeople,
  setFindAllPeopleFiltersPage,
  setFindAllPeopleFiltersTargetList,
} from "../../database/people/slice";
import { TargetListPageEnum } from "../dto";
import {
  findTargetListById,
  setAddContactsStatus,
  setFindTargetListStatus,
  setRemoveContactsStatus,
  setTargetListPage,
} from "../slice";
import { TargetListCompanies } from "./companies";
import { Form } from "./form";
import { TargetListPeople } from "./people";

export function EditTargetList() {
  const dispatch = useAppDispatch();

  const targetListState = useAppSelector((state) => state.targetList);
  const companiesState = useAppSelector((state) => state.companies);
  const peopleState = useAppSelector((state) => state.people);

  const [direction, setDirection] = useState<"left" | "right">("left");
  const [tabValue, setTabValue] = useState<number>(0);
  const [isFirstLoadCompanies, setIsFirstLoadCompanies] = useState(true);
  const [isFirstLoadPeople, setIsFirstLoadPeople] = useState(true);

  useEffect(() => {
    dispatch(findTargetListById(targetListState.selectedTargetList!));
  }, []);

  useEffect(() => {
    if (!isFirstLoadCompanies) {
      dispatch(setFindAllCompaniesFiltersPage(0));
      dispatch(
        findAllCompanies({
          ...companiesState.filters,
          page: 0,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                  {
                    value: targetListState.findTargetListResponse?.id!,
                    label: targetListState.findTargetListResponse?.name!,
                  },
                ]
              : [],
        })
      );
    }
  }, [
    companiesState.filters.order,
    companiesState.filters.sort,
    companiesState.filters.name,
    companiesState.filters.atecos,
    companiesState.filters.category,
    companiesState.filters.city,
    companiesState.filters.countryRegion,
    companiesState.filters.department,
    companiesState.filters.employees,
    companiesState.filters.privacy,
    companiesState.filters.revenue,
    companiesState.filters.role,
    companiesState.filters.sector,
    companiesState.filters.smartphone,
    companiesState.filters.state,
    companiesState.filters.zipCode,
    companiesState.filters.targetList,
    companiesState.filters.outcome,
    companiesState.filters.project,
  ]);

  useEffect(() => {
    if (!isFirstLoadPeople) {
      dispatch(setFindAllPeopleFiltersPage(0));
      dispatch(
        findAllPeople({
          ...peopleState.filters,
          page: 0,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? peopleState.filters.itemsPerPage
              : 0,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                  {
                    value: targetListState.findTargetListResponse?.id!,
                    label: targetListState.findTargetListResponse?.name!,
                  },
                ]
              : [],
        })
      );
    }
  }, [
    peopleState.filters.order,
    peopleState.filters.sort,
    peopleState.filters.name,
    peopleState.filters.atecos,
    peopleState.filters.category,
    peopleState.filters.city,
    peopleState.filters.countryRegion,
    peopleState.filters.department,
    peopleState.filters.employees,
    peopleState.filters.privacy,
    peopleState.filters.revenue,
    peopleState.filters.role,
    peopleState.filters.sector,
    peopleState.filters.smartphone,
    peopleState.filters.state,
    peopleState.filters.zipCode,
    peopleState.filters.targetList,
    peopleState.filters.personName,
    peopleState.filters.personEmail,
    peopleState.filters.hasCompany,
    peopleState.filters.project,
    peopleState.filters.outcome,
  ]);

  useEffect(() => {
    if (!isFirstLoadCompanies)
      dispatch(
        findAllCompanies({
          ...companiesState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? companiesState.filters.itemsPerPage
              : 0,
        })
      );
  }, [companiesState.filters.page]);

  useEffect(() => {
    if (!isFirstLoadPeople)
      dispatch(
        findAllPeople({
          ...peopleState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? peopleState.filters.itemsPerPage
              : 0,
        })
      );
  }, [peopleState.filters.page]);

  useEffect(() => {
    if (targetListState.findTargetListStatus === "successfully") {
      dispatch(
        findAllCompanies({
          ...companiesState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? companiesState.filters.itemsPerPage
              : 0,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                  {
                    label: targetListState.findTargetListResponse?.name!,
                    value: targetListState.findTargetListResponse?.id!,
                  },
                ]
              : [],
        })
      );
      dispatch(
        setFindAllPeopleFiltersTargetList(
          targetListState.targetListPage !== TargetListPageEnum.DB
            ? [
                {
                  label: targetListState.findTargetListResponse?.name!,
                  value: targetListState.findTargetListResponse?.id!,
                },
              ]
            : []
        )
      );
      dispatch(
        setFindAllCompaniesFiltersTargetList(
          targetListState.targetListPage !== TargetListPageEnum.DB
            ? [
                {
                  label: targetListState.findTargetListResponse?.name!,
                  value: targetListState.findTargetListResponse?.id!,
                },
              ]
            : []
        )
      );
      dispatch(
        findAllPeople({
          ...peopleState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? peopleState.filters.itemsPerPage
              : 0,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                  {
                    label: targetListState.findTargetListResponse?.name!,
                    value: targetListState.findTargetListResponse?.id!,
                  },
                ]
              : [],
        })
      );
      setIsFirstLoadCompanies(false);
      setIsFirstLoadPeople(false);
      dispatch(setFindTargetListStatus("idle"));
    }
  }, [targetListState.findTargetListStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListState.addContactsStatus === "failed" ||
      targetListState.addContactsStatus === "successfully"
    ) {
      if (targetListState.addContactsStatus === "successfully") {
        dispatch(
          findTargetListById(targetListState.findTargetListResponse?.id!)
        );
        dispatch(setTargetListPage(TargetListPageEnum.LIST));
      }
      timeoutId = setTimeout(() => {
        dispatch(setAddContactsStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.addContactsStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListState.removeContactsStatus === "failed" ||
      targetListState.removeContactsStatus === "successfully"
    ) {
      if (targetListState.removeContactsStatus === "successfully") {
        dispatch(
          findTargetListById(targetListState.findTargetListResponse?.id!)
        );
        dispatch(setTargetListPage(TargetListPageEnum.LIST));
      }
      timeoutId = setTimeout(() => {
        dispatch(setRemoveContactsStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.removeContactsStatus]);

  return (
    <>
      <div className="fixed flex flex-col gap-4 top-6 right-6">
        <Banner
          label={
            (tabValue === 1 ? "Persone " : "Aziende") + " aggiunte alla lista."
          }
          visible={targetListState.addContactsStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setAddContactsStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'aggiunta delle " +
            (tabValue === 1 ? "persone " : "aziende") +
            " alla lista target."
          }
          visible={targetListState.addContactsStatus === "failed"}
          closeAction={function (): void {
            dispatch(setAddContactsStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      {targetListState.findTargetListStatus === "loading" ? (
        <Spinner />
      ) : (
        <div className="flex flex-col bg-white rounded-2xl shadow-sm m-6">
          <div className="p-4 pb-0 flex justify-between items-center">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-6">
                <span className="text-heading-xs font-semibold text-neutral-800">
                  {targetListState.findTargetListResponse?.name}
                </span>
                <TabMenu
                  direction={direction}
                  setDirection={(value) => setDirection(value)}
                  setValue={(e) => {
                    setTabValue(e);
                    if (e === 1 || e === 2) {
                      dispatch(setTargetListPage(TargetListPageEnum.LIST));
                    }
                  }}
                  value={tabValue}
                  items={[
                    {
                      icon: <></>,
                      label: "Specifiche lista",
                    },
                    {
                      icon: <></>,
                      label:
                        "Persone (" +
                        Object.keys(
                          targetListState.findTargetListResponse
                            ?.personContacts ?? {}
                        ).length +
                        ")",
                    },
                    {
                      icon: <></>,
                      label:
                        "Aziende (" +
                        Object.keys(
                          targetListState.findTargetListResponse
                            ?.companyContacts ?? {}
                        ).length +
                        ")",
                    },
                  ]}
                />
              </div>
              {tabValue !== 0 &&
                targetListState.targetListPage !== TargetListPageEnum.CSV &&
                targetListState.targetListPage !== TargetListPageEnum.DB &&
                targetListState.targetListPage !== TargetListPageEnum.TL &&
                targetListState.targetListPage !== TargetListPageEnum.ADD && (
                  <Button
                    size={"sm"}
                    iconPosition={"left"}
                    variant={"outline"}
                    color={"blue"}
                    icon={<PlusIcon color={""} size={0} />}
                    label={
                      tabValue === 2 ? "Aggiungi aziende" : "Aggiungi persone"
                    }
                    onClick={() =>
                      dispatch(setTargetListPage(TargetListPageEnum.ADD))
                    }
                  />
                )}
            </div>
          </div>
          <TabPages
            direction={direction}
            setDirection={(value) => setDirection(value)}
            pages={[
              <div key={0} className="max-h-full">
                <Form />
              </div>,
              <div key={1} className="h-full">
                <TargetListPeople />
              </div>,
              <div key={2} className="h-full">
                <TargetListCompanies />
              </div>,
            ]}
            value={tabValue}
            setValue={(e) => {
              setTabValue(e);
            }}
          />
        </div>
      )}
    </>
  );
}
