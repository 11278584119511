import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../lib/types";
import {
  FindAllTargetListsFilters,
  FindAllTargetListsResponse,
} from "../targetList/dto";
import { NewTargetListsService } from "../targetList/service";
import {
  FindAllCompaniesFilters,
  FindAllCompaniesResponse,
} from "./companies/dto";
import { NewCompaniesService } from "./companies/service";
import {
  FindAllProjectsFilters,
  FindAllProjectsResponse,
} from "../projects/dto";
import { NewProjectsService } from "../projects/service";

interface DatabaseState {
  tabValue: number;
  filtersActive: boolean;
  optionsActive: boolean;
  option?: "columns";
  companiesForFiltersResponse?: FindAllCompaniesResponse;
  companiesForFiltersStatus: PromiseStatuses;
  targetListsForFiltersResponse?: FindAllTargetListsResponse;
  targetListsForFiltersStatus: PromiseStatuses;
  projectsForFiltersResponse?: FindAllProjectsResponse;
  projectsForFiltersStatus: PromiseStatuses;
}

const initialState: DatabaseState = {
  tabValue: 0,
  filtersActive: true,
  optionsActive: false,
  companiesForFiltersStatus: "idle",
  targetListsForFiltersStatus: "idle",
  projectsForFiltersStatus: "idle",
};

export const findAllCompaniesForFilters = createAsyncThunk(
  "database/findAllCompaniesForFilters",
  async (): Promise<FindAllCompaniesResponse> => {
    const CompaniesService = NewCompaniesService();
    const filters: FindAllCompaniesFilters = {
      order: true,
      sort: "name",
      itemsPerPage: 0,
      page: 0,
      name: [],
      atecos: [],
      sector: [],
      category: [],
      department: [],
      role: [],
      revenue: undefined,
      employees: undefined,
      smartphone: undefined,
      privacy: undefined,
      state: undefined,
      city: [],
      countryRegion: [],
      zipCode: [],
      targetList: [],
      outcome: [],
      project: [],
    };

    return CompaniesService.findAllCompanies(filters);
  }
);

export const findAllTargetListForFilters = createAsyncThunk(
  "database/findAllTargetListForFilters",
  async (): Promise<FindAllTargetListsResponse> => {
    const TargetListService = NewTargetListsService();
    const filters: FindAllTargetListsFilters = {
      order: true,
      sort: "name",
      itemsPerPage: 0,
      page: 0,
      sector: [],
      commercial: [],
      contacts: "",
      name: "",
      state: [],
      teamLeader: [],
      project: [],
    };

    return TargetListService.findAllTargetLists(filters);
  }
);

export const findAllProjectsForFilters = createAsyncThunk(
  "database/findAllProjectsForFilters",
  async (): Promise<FindAllProjectsResponse> => {
    const ProjectState = NewProjectsService();
    const filters: FindAllProjectsFilters = {
      order: true,
      sort: "name",
      itemsPerPage: 0,
      page: 0,
      sector: [],
      commercial: [],
      name: "",
      state: [],
      startDate: [],
    };

    return ProjectState.findAllProjects(filters);
  }
);

const databaseSlice = createSlice({
  name: "database/slice",
  initialState,
  reducers: {
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    setFiltersActive: (state, action) => {
      state.filtersActive = action.payload;
    },
    setOptionsActive: (state, action) => {
      state.optionsActive = action.payload;
    },
    setOption: (state, action) => {
      state.option = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(findAllCompaniesForFilters.pending, (state) => {
        state.companiesForFiltersStatus = "loading";
      })
      .addCase(findAllCompaniesForFilters.fulfilled, (state, action) => {
        state.companiesForFiltersStatus = "successfully";
        state.companiesForFiltersResponse = action.payload;
      })
      .addCase(findAllCompaniesForFilters.rejected, (state) => {
        state.companiesForFiltersStatus = "failed";
      })
      .addCase(findAllTargetListForFilters.pending, (state) => {
        state.targetListsForFiltersStatus = "loading";
      })
      .addCase(findAllTargetListForFilters.fulfilled, (state, action) => {
        state.targetListsForFiltersStatus = "successfully";
        state.targetListsForFiltersResponse = action.payload;
      })
      .addCase(findAllTargetListForFilters.rejected, (state) => {
        state.targetListsForFiltersStatus = "failed";
      })
      .addCase(findAllProjectsForFilters.pending, (state) => {
        state.projectsForFiltersStatus = "loading";
      })
      .addCase(findAllProjectsForFilters.fulfilled, (state, action) => {
        state.projectsForFiltersStatus = "successfully";
        state.projectsForFiltersResponse = action.payload;
      })
      .addCase(findAllProjectsForFilters.rejected, (state) => {
        state.projectsForFiltersStatus = "failed";
      });
  },
});

export const { setTabValue, setFiltersActive, setOptionsActive, setOption } =
  databaseSlice.actions;

export default databaseSlice.reducer;
