import React from "react";
import { IconProps } from "./dto";

export function FileTextIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.3333 5.33398H16C14.5855 5.33398 13.2289 5.89589 12.2287 6.89608C11.2285 7.89628 10.6666 9.25283 10.6666 10.6673V53.334C10.6666 54.7485 11.2285 56.105 12.2287 57.1052C13.2289 58.1054 14.5855 58.6673 16 58.6673H48C49.4144 58.6673 50.771 58.1054 51.7712 57.1052C52.7714 56.105 53.3333 54.7485 53.3333 53.334V21.334M37.3333 5.33398L53.3333 21.334M37.3333 5.33398V21.334H53.3333M42.6666 34.6673H21.3333M42.6666 45.334H21.3333M26.6666 24.0007H21.3333" stroke={props.color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}