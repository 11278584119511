import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../lib/types";
import {
  FindAllProjectsResponse,
  ProjectStateEnum,
} from "../../pages/projects/dto";
import { NewProjectsService } from "../../pages/projects/service";
import {
  FindAllTargetListsResponse,
  TargetListStatusEnum,
} from "../../pages/targetList/dto";
import { NewTargetListsService } from "../../pages/targetList/service";
import {
  CreatePreferenceRequest,
  FindAllPreferencesResponse,
} from "../../ui/organisms/sorting/dto";
import { AtecoDTO } from "./dto";
import { NewPreferencesService } from "./service";

interface PreferencesState {
  findAllStatus: PromiseStatuses;
  findAllResponse?: FindAllPreferencesResponse;
  createStatus: PromiseStatuses;
  updateStatus: PromiseStatuses;
  findAllAtecosResponse: AtecoDTO[];
  findAllTargetListSuspendedResponse?: FindAllTargetListsResponse;
  findAllTargetListSuspendedStatus: PromiseStatuses;
  findAllProjectSuspendedResponse?: FindAllProjectsResponse;
  findAllProjectSuspendedStatus: PromiseStatuses;
  findAllAtecosStatus: PromiseStatuses;
}

const initialState: PreferencesState = {
  findAllStatus: "idle",
  createStatus: "idle",
  updateStatus: "idle",
  findAllAtecosResponse: [],
  findAllAtecosStatus: "idle",
  findAllTargetListSuspendedStatus: "idle",
  findAllProjectSuspendedStatus: "idle",
};

export const findAllPreferences = createAsyncThunk(
  "preferences/findAllPreferences",
  async (owner: string, thunkApi): Promise<FindAllPreferencesResponse> => {
    const PreferencesService = NewPreferencesService();

    return PreferencesService.findAllPreferences(owner);
  }
);

export const findAllTargetListSuspended = createAsyncThunk(
  "preferences/findAllTargetListSuspended",
  async (): Promise<FindAllTargetListsResponse> => {
    const TargetListService = NewTargetListsService();

    return TargetListService.findAllTargetLists({
      commercial: [],
      contacts: "",
      itemsPerPage: 0,
      name: "",
      project: [],
      order: false,
      page: 0,
      sector: [],
      sort: "id",
      state: [TargetListStatusEnum.SUSPENDED],
      teamLeader: [],
    });
  }
);

export const findAllProjectSuspended = createAsyncThunk(
  "preferences/findAllProjectSuspended",
  async (): Promise<FindAllProjectsResponse> => {
    const newProjectsService = NewProjectsService();

    return newProjectsService.findAllProjects({
      state: [ProjectStateEnum.SUSPENDED],
      commercial: [],
      name: "",
      sector: [],
      startDate: [],
      order: false,
      itemsPerPage: 0,
      page: 0,
    });
  }
);

export const createPreferences = createAsyncThunk(
  "preferences/createPreferences",
  async (request: CreatePreferenceRequest, thunkApi): Promise<void> => {
    const PreferencesService = NewPreferencesService();

    return PreferencesService.createPreferences(request);
  }
);

export const updatePreferences = createAsyncThunk(
  "preferences/updatePreferences",
  async (
    request: { data: CreatePreferenceRequest; id: string },
    thunkApi
  ): Promise<void> => {
    const PreferencesService = NewPreferencesService();

    return PreferencesService.updatePreferences(request.data, request.id);
  }
);

export const findAllAtecos = createAsyncThunk(
  "preferences/findAllAtecos",
  async (_, thunkApi): Promise<AtecoDTO[]> => {
    const PreferencesService = NewPreferencesService();

    return PreferencesService.findAllAtecos();
  }
);

const preferencesSlice = createSlice({
  name: "preferences/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(findAllPreferences.pending, (state) => {
        state.findAllStatus = "loading";
      })
      .addCase(findAllPreferences.fulfilled, (state, action) => {
        state.findAllStatus = "successfully";
        state.findAllResponse = action.payload;
      })
      .addCase(findAllPreferences.rejected, (state) => {
        state.findAllStatus = "failed";
      })
      .addCase(createPreferences.pending, (state) => {
        state.createStatus = "loading";
      })
      .addCase(createPreferences.fulfilled, (state) => {
        state.createStatus = "successfully";
      })
      .addCase(createPreferences.rejected, (state) => {
        state.createStatus = "failed";
      })
      .addCase(updatePreferences.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(updatePreferences.fulfilled, (state) => {
        state.updateStatus = "successfully";
      })
      .addCase(updatePreferences.rejected, (state) => {
        state.updateStatus = "failed";
      })
      .addCase(findAllAtecos.pending, (state) => {
        state.findAllAtecosStatus = "loading";
      })
      .addCase(findAllAtecos.fulfilled, (state, action) => {
        state.findAllAtecosStatus = "successfully";
        state.findAllAtecosResponse = action.payload;
      })
      .addCase(findAllAtecos.rejected, (state) => {
        state.findAllAtecosStatus = "failed";
      })
      .addCase(findAllTargetListSuspended.pending, (state) => {
        state.findAllTargetListSuspendedStatus = "loading";
      })
      .addCase(findAllTargetListSuspended.fulfilled, (state, action) => {
        state.findAllTargetListSuspendedStatus = "successfully";
        state.findAllTargetListSuspendedResponse = action.payload;
      })
      .addCase(findAllTargetListSuspended.rejected, (state) => {
        state.findAllTargetListSuspendedStatus = "failed";
      })
      .addCase(findAllProjectSuspended.pending, (state) => {
        state.findAllProjectSuspendedStatus = "loading";
      })
      .addCase(findAllProjectSuspended.fulfilled, (state, action) => {
        state.findAllProjectSuspendedStatus = "successfully";
        state.findAllProjectSuspendedResponse = action.payload;
      })
      .addCase(findAllProjectSuspended.rejected, (state) => {
        state.findAllProjectSuspendedStatus = "failed";
      });
  },
  reducers: {
    setFindAllPreferencesStatus: (state, action) => {
      state.findAllStatus = action.payload;
    },
    setCreatePreferencesStatus: (state, action) => {
      state.createStatus = action.payload;
    },
    setUpdatePreferencesStatus: (state, action) => {
      state.updateStatus = action.payload;
    },
    setFindAllTargetListsSuspendedStatus: (state, action) => {
      state.findAllTargetListSuspendedStatus = action.payload;
    },
    setFindAllProjectsSuspendedStatus: (state, action) => {
      state.findAllProjectSuspendedStatus = action.payload;
    },
  },
});

export const {
  setFindAllPreferencesStatus,
  setCreatePreferencesStatus,
  setUpdatePreferencesStatus,
  setFindAllProjectsSuspendedStatus,
  setFindAllTargetListsSuspendedStatus,
} = preferencesSlice.actions;

export default preferencesSlice.reducer;
