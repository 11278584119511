import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import Button from "../../ui/molecules/button";
import Input from "../../ui/molecules/input/Input";
import { Spinner } from "../../ui/molecules/spinner";
import {
  editParticularity,
  findAllParticularities,
  setEditParticularityStatus,
  setSelectedParticularity,
} from "./slice";

export function EditParticularity() {
  const particularitiesState = useAppSelector((state) => state.particularities);

  const dispatch = useAppDispatch();

  const [errorName, setErrorName] = useState<boolean>(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      particularitiesState.editParticularityStatus === "failed" ||
      particularitiesState.editParticularityStatus === "successfully"
    ) {
      dispatch(setSelectedParticularity(undefined));
      if (particularitiesState.editParticularityStatus === "successfully") {
        dispatch(
          findAllParticularities({
            itemsPerPage: 15,
            order: true,
            page: 0,
            sort: "name",
            name: ''
          })
        );
      }
      timeoutId = setTimeout(() => {
        dispatch(setEditParticularityStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [particularitiesState.editParticularityStatus]);

  return (
    <div
      onClick={() => dispatch(setSelectedParticularity(undefined))}
      className={clsx(
        "transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]",
        {
          block: particularitiesState.selectedParticularity,
          hidden: !particularitiesState.selectedParticularity,
        }
      )}
    >
      {particularitiesState.editParticularityStatus === "loading" ? (
        <Spinner />
      ) : (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="transition-all flex flex-col items-center gap-8 p-[20px] bg-white rounded-[12px] opacity-100 shadow-sm min-w-[400px]"
        >
          <div className="flex flex-col items-center gap-2">
            <span className="text-text-lg font-semibold text-neutral-800">
              Modifica particolarità
            </span>
          </div>
          <div className="flex flex-col w-full h-full gap-3">
            <Input
              placeholder="Nome particolarità"
              error={errorName}
              value={particularitiesState.selectedParticularity?.name}
              onChangeText={(e) =>
                dispatch(
                  setSelectedParticularity({
                    name: e,
                    id: particularitiesState.selectedParticularity?.id,
                  })
                )
              }
              supportingText={
                errorName ? "Inserisci il nome della particolarità" : undefined
              }
            />
          </div>
          <div className="w-full flex flex-1 gap-3 mt-3">
            <div className="w-1/2">
              <Button
                fullWidth
                size={"sm"}
                iconPosition={"off"}
                variant={"outline"}
                color={"gray"}
                label="Annulla"
                onClick={() => dispatch(setSelectedParticularity(undefined))}
              />
            </div>
            <div className="w-1/2">
              <Button
                fullWidth
                size={"sm"}
                iconPosition={"off"}
                variant={"solid"}
                color={"blue"}
                label="Conferma"
                onClick={() => {
                  if (
                    !particularitiesState.selectedParticularity?.name ||
                    particularitiesState.selectedParticularity?.name ===
                      undefined ||
                    particularitiesState.selectedParticularity?.name === ""
                  ) {
                    setErrorName(true);
                  } else {
                    dispatch(
                      editParticularity({
                        data: {
                          name: particularitiesState.selectedParticularity
                            ?.name!,
                        },
                        id: particularitiesState.selectedParticularity?.id!,
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
