import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { colors } from "../../../../../ui/colors";
import { ArrowRightIcon } from "../../../../../ui/icons/arrowRight";
import { UploadIcon } from "../../../../../ui/icons/upload";
import Button from "../../../../../ui/molecules/button";
import { SelectCustom } from "../../../../../ui/molecules/select";
import { Spinner } from "../../../../../ui/molecules/spinner";
import {
  ImportCompanyDTO,
  TargetListPageEnum,
} from "../../../../targetList/dto";
import {
  findTargetListById,
  importContactCompanyCSV,
  setTargetListPage,
} from "../../../../targetList/slice";
import { findProjectById } from "../../../../projects/slice";

interface Props {
  step: number;
  setStep: (value: number) => void;
  name: string;
  delimiter: string;
  fields: string[];
  file: File | null;
  rows: Record<string, any>[];
}
export function AssociateForCSV(props: Props) {
  const dispatch = useAppDispatch();
  const targetListState = useAppSelector((state) => state.targetList);

  useEffect(() => {
    if (
      targetListState.importCompanyStatus === "failed" ||
      targetListState.importCompanyStatus === "successfully"
    ) {
      dispatch(
        findProjectById(targetListState.findTargetListResponse?.projectId!)
      );
      dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
      dispatch(setTargetListPage(TargetListPageEnum.LIST));
    }
  }, [targetListState.importCompanyStatus]);

  const [headers, setHeaders] = useState<ImportCompanyDTO>({
    name: [],
    phone: [],
    notes: [],
    lastContactDate: [],
    email: [],
    contactState: [],
    vat: [],
    address: [],
    zipCode: [],
    city: [],
    province: [],
    countryRegion: [],
    state: [],
    website: [],
    revenue: [],
    employeesNumber: [],
    atecoCode: [],
    atecoDescription: [],
    sector: [],
    category: [],
  });

  useEffect(() => {
    const newHeaders = { ...headers };

    const name = props.fields.find((value) => value.toLowerCase() === "nome");
    if (name) {
      newHeaders.name = [name];
    }
    const vat = props.fields.find((value) => value.toLowerCase() === "p. iva");
    if (vat) {
      newHeaders.vat = [vat];
    }
    const address = props.fields.find(
      (value) => value.toLowerCase() === "indirizzo"
    );
    if (address) {
      newHeaders.address = [address];
    }
    const zipCode = props.fields.find((value) => value.toLowerCase() === "cap");
    if (zipCode) {
      newHeaders.zipCode = [zipCode];
    }
    const city = props.fields.find((value) => value.toLowerCase() === "città");
    if (city) {
      newHeaders.city = [city];
    }
    const province = props.fields.find(
      (value) => value.toLowerCase() === "provincia"
    );
    if (province) {
      newHeaders.province = [province];
    }
    const countryRegion = props.fields.find(
      (value) => value.toLowerCase() === "regione"
    );
    if (countryRegion) {
      newHeaders.countryRegion = [countryRegion];
    }
    const state = props.fields.find((value) => value.toLowerCase() === "stato");
    if (state) {
      newHeaders.state = [state];
    }
    const email = props.fields.find((value) => value.toLowerCase() === "email");
    if (email) {
      newHeaders.email = [email];
    }
    const website = props.fields.find(
      (value) => value.toLowerCase() === "sito web"
    );
    if (website) {
      newHeaders.website = [website];
    }
    const revenue = props.fields.find(
      (value) => value.toLowerCase() === "fatturato"
    );
    if (revenue) {
      newHeaders.revenue = [revenue];
    }
    const employeesNumber = props.fields.find(
      (value) => value.toLowerCase() === "numero dipendenti"
    );
    if (employeesNumber) {
      newHeaders.employeesNumber = [employeesNumber];
    }
    const atecoCode = props.fields.find(
      (value) => value.toLowerCase() === "codice ateco"
    );
    if (atecoCode) {
      newHeaders.atecoCode = [atecoCode];
    }
    const atecoDescription = props.fields.find(
      (value) => value.toLowerCase() === "descrizione ateco"
    );
    if (atecoDescription) {
      newHeaders.atecoDescription = [atecoDescription];
    }
    const sector = props.fields.find(
      (value) => value.toLowerCase() === "settore"
    );
    if (sector) {
      newHeaders.sector = [sector];
    }
    const category = props.fields.find(
      (value) => value.toLowerCase() === "categoria"
    );
    if (category) {
      newHeaders.category = [category];
    }
    const phone = props.fields.find(
      (value) => value.toLowerCase() === "telefono"
    );
    if (phone) {
      newHeaders.phone = [phone];
    }
    const notes = props.fields.find((value) => value.toLowerCase() === "note");
    if (notes) {
      newHeaders.notes = [notes];
    }
    const lastContactDate = props.fields.find(
      (value) => value.toLowerCase() === "data ultimo contatto"
    );
    if (lastContactDate) {
      newHeaders.lastContactDate = [lastContactDate];
    }
    const contactState = props.fields.find(
      (value) => value.toLowerCase() === "esito"
    );
    if (contactState) {
      newHeaders.contactState = [contactState];
    }

    setHeaders(newHeaders);
  }, [props.fields]);

  const formData = new FormData();

  const selectedFields = Object.values(headers)
    .flat()
    .filter((field) => field);

  const getFilteredOptions = (currentHeaderKey: keyof ImportCompanyDTO) => {
    const selectedHeaderFields = headers[currentHeaderKey];

    return props.fields
      .filter(
        (field) =>
          !selectedFields.includes(field) ||
          selectedHeaderFields.includes(field)
      )
      .map((field) => ({
        label: field,
        value: field,
      }));
  };

  return (
    <div>
      <div className="p-4 bg-brandPrimary-50 border-y border-y-neutral-100 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span
            className="text-text-md font-bold text-neutral-400 cursor-pointer"
            onClick={() => dispatch(setTargetListPage(TargetListPageEnum.ADD))}
          >
            Seleziona il tipo di importazione
          </span>
          <ArrowRightIcon color={colors.neutral[500]} size={24} />
          <span
            onClick={() => props.setStep(0)}
            className="text-text-md font-bold text-neutral-400 cursor-pointer"
          >
            Aggiungi da file CSV
          </span>
          <ArrowRightIcon color={colors.neutral[500]} size={24} />
          <span className="text-text-md font-bold text-neutral-800">
            Mappatura campi
          </span>
        </div>
        {targetListState.importCompanyStatus === "loading" ? (
          <Spinner />
        ) : (
          <Button
            onClick={() => {
              const filteredHeaders = Object.fromEntries(
                Object.entries(headers).filter(
                  ([key, value]) => Array.isArray(value) && value.length > 0
                )
              );
              formData.delete("file");
              formData.delete("headers");
              formData.delete("delimiter");
              if (props.file !== null) formData.append("file", props.file);
              formData.append("headers", JSON.stringify(filteredHeaders));
              formData.append("delimiter", props.delimiter);
              dispatch(
                importContactCompanyCSV({
                  data: formData,
                  id: targetListState.findTargetListResponse?.id!,
                })
              );
            }}
            disabled={!props.file}
            size={"sm"}
            iconPosition={"right"}
            variant={"outline"}
            color={"blue"}
            label="Importa contatti"
            icon={<UploadIcon color={""} size={0} />}
          />
        )}
      </div>
      <div className="p-4">
        <span className="text-text-md font-bold text-neutral-800">
          Anteprima file CSV
        </span>
      </div>
      <div>
        <table className="w-full" align="left">
          <thead>
            <tr className="bg-neutral-100">
              {props.fields.map((field) => (
                <th
                  align="left"
                  className="first:pl-4 last:pr-4 h-10 text-label-sm font-semibold text-neutral-800"
                >
                  {field}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.rows.slice(0, 3).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {props.fields.map((field) => (
                  <td
                    key={field}
                    className="first:pl-4 last:pr-4 h-10 text-label-sm text-neutral-700"
                  >
                    {row[field] || "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bg-brandPrimary-100 p-4 gap-6 flex flex-col items-start overflow-auto">
        <span className="text-text-md font-bold text-neutral-800">
          Mappa campi CRM
        </span>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.name[0]}
            isClearable
            label="Nome"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.name = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.name = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il nome dell'azienda"}
            options={getFilteredOptions("name")}
          />
          <SelectCustom
            defaultValue={headers.vat[0]}
            isClearable
            label="P. IVA"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.vat = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.vat = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la P. IVA"}
            options={getFilteredOptions("vat")}
          />
          <SelectCustom
            defaultValue={headers.email[0]}
            isClearable
            label="Email"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.email = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.email = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per l'email"}
            options={getFilteredOptions("email")}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.address[0]}
            isClearable
            label="Indirizzo"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.address = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.address = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per l'indirizzo"}
            options={getFilteredOptions("address")}
          />
          <SelectCustom
            defaultValue={headers.zipCode[0]}
            isClearable
            label="CAP"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.zipCode = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.zipCode = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il CAP"}
            options={getFilteredOptions("zipCode")}
          />
          <SelectCustom
            defaultValue={headers.city[0]}
            isClearable
            label="Città"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.city = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.city = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la città"}
            options={getFilteredOptions("city")}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.province[0]}
            isClearable
            label="Provincia"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.province = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.province = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la provincia"}
            options={getFilteredOptions("province")}
          />
          <SelectCustom
            defaultValue={headers.countryRegion[0]}
            isClearable
            label="Regione"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.countryRegion = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.countryRegion = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la regione"}
            options={getFilteredOptions("countryRegion")}
          />
          <SelectCustom
            defaultValue={headers.state[0]}
            isClearable
            label="Stato"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.state = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.state = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per lo stato"}
            options={getFilteredOptions("state")}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.website[0]}
            isClearable
            label="Sito web"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.website = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.website = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il sito web"}
            options={getFilteredOptions("website")}
          />
          <SelectCustom
            defaultValue={headers.revenue[0]}
            isClearable
            label="Fatturato"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.revenue = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.revenue = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il fatturato"}
            options={getFilteredOptions("revenue")}
          />
          <SelectCustom
            defaultValue={headers.employeesNumber[0]}
            isClearable
            label="Numero dipendenti"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.employeesNumber = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.employeesNumber = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il numero di dipendenti"}
            options={getFilteredOptions("employeesNumber")}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.atecoCode[0]}
            isClearable
            label="Codice ATECO"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.atecoCode = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.atecoCode = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il codice ATECO"}
            options={getFilteredOptions("atecoCode")}
          />
          <SelectCustom
            defaultValue={headers.atecoDescription[0]}
            isClearable
            label="Descrizione ATECO"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.atecoDescription = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.atecoDescription = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la descrizione dell'ATECO"}
            options={getFilteredOptions("atecoDescription")}
          />
          <SelectCustom
            defaultValue={headers.sector[0]}
            isClearable
            label="Settore"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.sector = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.sector = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il settore"}
            options={getFilteredOptions("sector")}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.category[0]}
            isClearable
            label="Categoria"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.category = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.category = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la categoria"}
            options={getFilteredOptions("category")}
          />
          <SelectCustom
            defaultValue={headers.phone[0]}
            isClearable
            label="Telefono"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.phone = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.phone = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il numero di telefono"}
            options={getFilteredOptions("phone")}
          />
          <SelectCustom
            defaultValue={headers.contactState[0]}
            isClearable
            label="Esito"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.contactState = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.contactState = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per l'esito"}
            options={getFilteredOptions("contactState")}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.lastContactDate[0]}
            isClearable
            label="Data ultimo contatto"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.lastContactDate = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.lastContactDate = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la data dell'ultimo contatto"}
            options={getFilteredOptions("lastContactDate")}
          />
          <SelectCustom
            defaultValue={headers.notes[0]}
            isClearable
            label="Note"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.notes = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.notes = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per le note"}
            options={getFilteredOptions("notes")}
          />
        </div>
      </div>
    </div>
  );
}
