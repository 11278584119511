import qs from "qs";
import { cdAxios } from "../../lib/axios";
import { keycloak } from "../../lib/keycloak";
import { ImportCSVResponseDTO } from "../database/dto";
import {
  ContactStateEnum,
  FindAllTargetListsFilters,
  FindAllTargetListsResponse,
  NewTargetListRequest,
  TargetListDTO,
  TargetListStatusEnum,
} from "./dto";
import { TargetListsService } from "./service";

export class TargetListsServiceImpl implements TargetListsService {
  public findAllTargetLists(
    filters: FindAllTargetListsFilters
  ): Promise<FindAllTargetListsResponse> {
    return cdAxios
      .get("/api/target-lists", {
        params: {
          itemsPerPage:
            filters.itemsPerPage === 0 ? undefined : filters.itemsPerPage,
          page: filters.page,
          sort: filters.sort,
          order: filters.order,
          name: filters.name,
          sector: filters.sector,
          commercial: filters.commercial,
          project: filters.project,
          teamLeader: filters.teamLeader,
          state: filters.state,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: false,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public importContactCSV(
    data: FormData,
    type: "persons" | "companies",
    targetListId: string
  ): Promise<ImportCSVResponseDTO> {
    return cdAxios
      .put("/api/target-lists/" + targetListId + "/" + type + "/csv", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }

  public removeContacts(
    targetListId: string,
    type: "persons" | "companies",
    contactIds: string[]
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" + targetListId + "/" + type + "/remove",
        contactIds,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public addContacts(
    targetListId: string,
    type: "persons" | "companies",
    contactIds: string[]
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" + targetListId + "/" + type + "/add",
        contactIds,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public changeContactStatus(
    targetListId: string,
    type: "persons" | "companies",
    contactState: ContactStateEnum,
    contactId: string
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" +
          targetListId +
          "/" +
          type +
          "/" +
          contactId +
          "/state?state=" +
          contactState,
        undefined,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public findTargetListById(id: string): Promise<TargetListDTO> {
    let idToUse: string = "";
    if (window.location.pathname.includes("edit-project")) {
      if (window.location.pathname.split("/")[4] === undefined) {
        idToUse = id;
      } else {
        idToUse = window.location.pathname.split("/")[4];
      }
    } else if (window.location.pathname.includes("edit-target-list")) {
      if (window.location.pathname.split("/")[2] === undefined) {
        idToUse = id;
      } else {
        idToUse = window.location.pathname.split("/")[2];
      }
    } else {
      idToUse = id;
    }
    return cdAxios
      .get("/api/target-lists/" + idToUse, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public createTargetList(request: NewTargetListRequest): Promise<string> {
    return cdAxios
      .post("/api/target-lists", request, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }

  public changeStatus(
    request: {
      state:
        | TargetListStatusEnum.CONCLUDED
        | TargetListStatusEnum.INPROGRESS
        | undefined;
      id: string;
    }[]
  ): Promise<void> {
    return cdAxios
      .put("/api/target-lists/state", request, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }

  public deleteTargetList(id: string): Promise<void> {
    return cdAxios
      .delete("/api/target-lists/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }
}
