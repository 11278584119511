import React from "react";
import { IconProps } from "./dto";

export function FileXlsIcon(props: IconProps) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 33 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7881 2.42442V11.5153C18.7881 12.8542 19.8734 13.9396 21.2123 13.9396H30.3032M8.48503 25.4547H13.9396M13.9396 25.4547H24.8487M13.9396 25.4547V31.5153M13.9396 25.4547V20.6062M6.66685 38.182H26.0608C28.7385 38.182 30.9093 36.0113 30.9093 33.3335V14.7825C30.9093 13.8521 30.5526 12.9571 29.9127 12.2816L21.0759 2.95383C20.3892 2.22896 19.4346 1.81836 18.4361 1.81836H6.66685C3.9891 1.81836 1.81836 3.9891 1.81836 6.66684V33.3335C1.81836 36.0113 3.9891 38.182 6.66685 38.182ZM9.09109 32.1214H23.6365C24.306 32.1214 24.8487 31.5787 24.8487 30.9093V21.2123C24.8487 20.5429 24.306 20.0002 23.6365 20.0002H9.09109C8.42165 20.0002 7.87897 20.5429 7.87897 21.2123V30.9093C7.87897 31.5787 8.42165 32.1214 9.09109 32.1214Z"
        stroke={props.color}
        strokeWidth="1.81818"
      />
    </svg>
  );
}
