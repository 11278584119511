import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { CheckCircleIcon } from "../../../../ui/icons/checkCircle";
import { SelectCustom } from "../../../../ui/molecules/select";
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter";
import { ContactStateEnum } from "../../../targetList/dto";
import { setFindAllCompaniesFiltersOutcome } from "../slice";

export function OutcomeFilter() {
  const companiesState = useAppSelector((state) => state.companies);

  const dispatch = useAppDispatch();

  return (
    <AccordionFilter
      key={"company-filter-outcome"}
      label={"Esito"}
      icon={<CheckCircleIcon color={""} size={0} />}
      showClear={companiesState.filters.outcome.length > 0}
      options={companiesState.filters.outcome}
      clearAction={function (): void {
        dispatch(setFindAllCompaniesFiltersOutcome([]));
      }}
      deleteFilter={function (option): void {
        dispatch(
          setFindAllCompaniesFiltersOutcome(
            companiesState.filters.outcome.filter(
              (name) => name.value !== option.value
            )
          )
        );
      }}
    >
      <SelectCustom
        isMulti
        isClearable={false}
        onChange={(e) => {
          if (e !== undefined && typeof e !== "string") {
            dispatch(
              setFindAllCompaniesFiltersOutcome(
                e.map((option) => ({ value: option, label: option }))
              )
            );
          }
        }}
        defaultValue={companiesState.filters.outcome.map(
          (option) => option.value
        )}
        placeholder={"Cerca per esito"}
        options={[
          { label: "In attesa", value: ContactStateEnum.DRAFT },
          { label: "Lead preso", value: ContactStateEnum.LEADTAKEN },
          { label: "Da richiamare", value: ContactStateEnum.TORECALL },
          { label: "Ko", value: ContactStateEnum.KO },
          { label: "Info errate", value: ContactStateEnum.INCORRECT },
        ]}
        noOptionsMessage="Non sono presenti esiti."
      />
    </AccordionFilter>
  );
}
