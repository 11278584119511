export const peopleColumns = [
  {
    column: "Contatto",
    visible: true,
    hideable: false,
  },
  {
    column: "Telefono",
    visible: true,
    hideable: false,
  },
  {
    column: "Email",
    visible: true,
    hideable: false,
  },
  {
    column: "Profilo Linkedin",
    visible: true,
    hideable: false,
  },
  {
    column: "Ruolo",
    visible: true,
    hideable: false,
  },
  {
    column: "Azienda",
    visible: true,
    hideable: false,
  },
  {
    column: "Reparto",
    visible: true,
    hideable: false,
  },
  {
    column: "Privacy",
    visible: true,
    hideable: false,
  },
];

export const companyColumns = [
  { column: "Azienda", visible: true, hideable: false },
  { column: "Fatturato", visible: true, hideable: false },
  { column: "Telefono", visible: true, hideable: false },
  { column: "Email", visible: true, hideable: true },
  { column: "Indirizzo", visible: true, hideable: true },
  { column: "CAP", visible: true, hideable: true },
  { column: "Città", visible: true, hideable: true },
  { column: "Provincia", visible: true, hideable: true },
  { column: "Regione", visible: true, hideable: true },
  { column: "Stato", visible: true, hideable: true },
  { column: "P. IVA", visible: true, hideable: true },
  { column: "Cod. ATECO", visible: true, hideable: false },
  { column: "Settore", visible: true, hideable: false },
  { column: "Categoria", visible: true, hideable: true },
  { column: "Dipendenti", visible: true, hideable: true },
  { column: "Sito web", visible: true, hideable: true },
];

export const targetListColumns = [
  { column: "Nome", visible: true, hideable: false },
  { column: "Commerciali", visible: true, hideable: false },
  { column: "Team Leader", visible: true, hideable: false },
  { column: "Settore", visible: true, hideable: false },
  { column: "Contatti", visible: true, hideable: false },
  { column: "Lead", visible: true, hideable: false },
  { column: "Da lavorare", visible: true, hideable: false },
  { column: "Stato", visible: true, hideable: false },
];

export const signOptions = [
  { label: "Uguale a", value: "=" },
  { label: "Maggiore di", value: ">" },
  { label: "Minore di", value: "<" },
  { label: "Compreso tra", value: "><" },
];

export interface ImportCSVResponseDTO {
  ids: number[];
  missed: number[];
}

export interface SearchPlacesDTO {
  type: string;
  features: PlaceFeatureDTO[];
  attribution: string;
}

export interface PlaceFeatureDTO {
  type: "Feature";
  id: string;
  geometry: {
    type: "Point";
    coordinates: number[];
  };
  properties: {
    mapbox_id: string;
    feature_type: string;
    full_address: string;
    name: string;
    name_preferred: string;
    coordinates: {
      longitude: string;
      latitude: string;
    };
    bbox: number[];
    context: {
      country: {
        mapbox_id: string;
        name: string;
        country_code: string;
        country_code_alpha_3: string;
        wikidata_id: string;
        translations: {
          it: {
            language: string;
            name: string;
          };
        };
      };
    };
  };
}
