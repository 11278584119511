import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Layout } from "../../../ui/layout";
import Button from "../../../ui/molecules/button";
import { Spinner } from "../../../ui/molecules/spinner";
import { Banner } from "../../../ui/organisms/banner";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import { EditTargetList } from "../../targetList/editTargetListDetails/index";
import {
  findAllTArgetLists,
  setRemoveContactsStatus,
  setSelectedTargetList,
  setTargetListsFilterPage,
} from "../../targetList/slice";
import {
  findClients,
  findCommercials,
  findTeamLeaders,
  findUsersAvatarsList,
} from "../../users/slice";
import { EditProjectPage } from "../dto";
import {
  editProject,
  findProjectById,
  setEditProjectDescription,
  setEditProjectPage,
  setEditProjectTargetListIds,
  setFindProjectStatus,
} from "../slice";
import { AddTargetList } from "./addTargetList";
import { Form } from "./form";
import { TargetLists } from "./targetLists";

export function EditProject() {
  const projectsState = useAppSelector((state) => state.projects);
  const targetListsState = useAppSelector((state) => state.targetList);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const [direction, setDirection] = useState<"left" | "right">("left");
  const [tabValue, setTabValue] = useState<number>(
    window.location.pathname.includes("specifics") ? 0 : 1
  );

  useEffect(() => {
    dispatch(findCommercials());
    dispatch(findClients());
    dispatch(findTeamLeaders());
    dispatch(findProjectById(projectsState.selectedProject!));
    if (window.location.pathname.includes("specifics")) {
      setTabValue(0);
    }
    if (window.location.pathname.includes("target-lists")) {
      setTabValue(1);
      if (window.location.pathname.split("/")[4]) {
        dispatch(setEditProjectPage(EditProjectPage.EDITLIST));
      }
    }
  }, []);

  useEffect(() => {
    if (projectsState.findProjectStatus === "successfully") {
      dispatch(setFindProjectStatus("idle"));
      dispatch(
        findUsersAvatarsList(
          projectsState.findProjectResponse?.targetLists
            ?.flatMap((data) => data.commercials)
            .map((commercial) => ({
              objectId: commercial.avatarObjectId,
              userId: commercial.id,
            }))!
        )
      );
      dispatch(
        findAllTArgetLists({
          commercial: [],
          contacts: "",
          itemsPerPage: 15,
          name: "",
          order: true,
          page: 0,
          sector: [],
          sort: "name",
          state: [],
          teamLeader: [],
          project: [projectsState.findProjectResponse?.id!],
        })
      );
      dispatch(
        setEditProjectDescription(
          projectsState.findProjectResponse?.description
        )
      );
      dispatch(
        setEditProjectTargetListIds(
          projectsState.findProjectResponse?.targetListsIds
            ? [...projectsState.findProjectResponse?.targetListsIds]
            : null
        )
      );
    }
  }, [projectsState.findProjectStatus]);

  useEffect(() => {
    if (targetListsState.selectedTargetList !== undefined) {
      navigate(
        window.location.pathname + "/" + targetListsState.selectedTargetList
      );
      dispatch(setEditProjectPage(EditProjectPage.EDITLIST));
      dispatch(setSelectedTargetList(undefined));
    }
  }, [targetListsState.selectedTargetList]);

  useEffect(() => {
    dispatch(setTargetListsFilterPage(0));
    dispatch(
      findAllTArgetLists({
        ...targetListsState.filters,
        page: 0,
        project: [projectsState.findProjectResponse?.id!],
      })
    );
  }, [
    targetListsState.filters.order,
    targetListsState.filters.sort,
    targetListsState.filters.name,
    targetListsState.filters.teamLeader,
    targetListsState.filters.commercial,
    targetListsState.filters.sector,
    targetListsState.filters.state,
  ]);

  useEffect(() => {
    dispatch(
      findAllTArgetLists({
        ...targetListsState.filters,
        project: [projectsState.findProjectResponse?.id!],
      })
    );
  }, [targetListsState.filters.page]);

  const editProjectMap = new Map<EditProjectPage, ReactElement>([
    [EditProjectPage.LIST, <TargetLists />],
    [EditProjectPage.ADDLIST, <AddTargetList />],
    [EditProjectPage.EDITLIST, <EditTargetList />],
  ]);

  const tabValuePathMap = new Map<number, string>([
    [0, "/specifics"],
    [1, "/target-lists"],
  ]);

  const navigate = useNavigate();

  return (
    <Layout
      menuItem={MenuItems.PROJECTS}
      breadcrumbItems={["Progetti", "Modifica progetto"]}
      headerLabel={projectsState.findProjectResponse?.name!}
      headerBackPath={"/projects"}
      headerChildren={
        <div className="flex items-center h-[100%] w-[100%] justify-between">
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => {
              setTabValue(e);
              navigate(
                "/edit-project/" +
                  window.location.pathname.split("/")[2] +
                  tabValuePathMap.get(e)
              );
              if (e === 1) {
                dispatch(setEditProjectPage(EditProjectPage.LIST));
              }
            }}
            value={tabValue}
            items={[
              {
                icon: <></>,
                label: "Specifiche",
              },
              {
                icon: <></>,
                label:
                  "Liste target (" +
                  projectsState.findProjectResponse?.targetLists?.length +
                  ")",
              },
            ]}
          />
          {tabValue === 0 &&
            (projectsState.newProjectStatus === "loading" ||
            projectsState.validateNewProject.status === "loading" ? (
              <Spinner />
            ) : (
              <Button
                size={"sm"}
                iconPosition={"off"}
                label="Modifica progetto"
                variant={"solid"}
                color={"blue"}
                onClick={() => {
                  dispatch(
                    editProject({
                      id: projectsState.findProjectResponse?.id!,
                      data: {
                        description:
                          projectsState.editProjectRequest.description,
                      },
                    })
                  );
                }}
              />
            ))}
        </div>
      }
    >
      <div className="fixed flex flex-col gap-4 top-6 right-6">
        <Banner
          label={"Contatto rimosso dalla lista."}
          visible={targetListsState.removeContactsStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setRemoveContactsStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante la rimozione del contatto dalla lista target."
          }
          visible={targetListsState.removeContactsStatus === "failed"}
          closeAction={function (): void {
            dispatch(setRemoveContactsStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      <TabPages
        direction={direction}
        setDirection={(value) => setDirection(value)}
        pages={[
          <div key={0} className="max-h-full overflow-auto">
            <Form />
          </div>,
          <div key={1} className="max-h-full overflow-auto">
            {editProjectMap.get(projectsState.editProjectPage)}
          </div>,
        ]}
        value={tabValue}
        setValue={(e) => {
          setTabValue(e);
        }}
      />
    </Layout>
  );
}
