import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { RocketIcon } from "../../../../ui/icons/rocket";
import { SelectCustom } from "../../../../ui/molecules/select";
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter";
import {
  setFindAllPeopleFiltersProject,
  setFindAllPeopleFiltersTargetList,
} from "../slice";
import { TargetListPageEnum } from "../../../targetList/dto";

export function ProjectFilter() {
  const peopleState = useAppSelector((state) => state.people);
  const databaseState = useAppSelector((state) => state.database);
  const targetListState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (peopleState.filters.project.length === 0) {
      dispatch(setFindAllPeopleFiltersTargetList([]));
    } else {
      peopleState.filters.project.forEach((project) => {
        const targetLists =
          databaseState.targetListsForFiltersResponse?.data.find(
            (_target) => _target.project.id === project.value
          );
        if (
          targetLists &&
          !peopleState.filters.targetList
            .map((cat) => cat.value)
            .includes(targetLists.id)
        ) {
          dispatch(
            setFindAllPeopleFiltersTargetList([
              ...peopleState.filters.targetList,
              { value: targetLists.id, label: targetLists.name },
            ])
          );
        }
      });
    }
  }, [peopleState.filters.project]);

  return (
    <AccordionFilter
      disabled={
        window.location.pathname.includes("target") &&
        targetListState.targetListPage !== TargetListPageEnum.DB
      }
      key={"person-filter-project"}
      label={"Progetto"}
      icon={<RocketIcon color={""} size={0} />}
      showClear={
        peopleState.filters.project.length > 0 &&
        (!window.location.pathname.includes("target") ||
          (window.location.pathname.includes("target") &&
            targetListState.targetListPage === TargetListPageEnum.DB))
      }
      options={peopleState.filters.project}
      clearAction={function (): void {
        dispatch(setFindAllPeopleFiltersProject([]));
      }}
      deleteFilter={function (option): void {
        dispatch(
          setFindAllPeopleFiltersProject(
            peopleState.filters.project.filter(
              (project) => project.value !== option.value
            )
          )
        );
      }}
    >
      <SelectCustom
        isMulti
        onChange={(e) => {
          if (e !== undefined && typeof e !== "string") {
            dispatch(
              setFindAllPeopleFiltersProject(
                e.map((option) => ({
                  value: option,
                  label: databaseState.projectsForFiltersResponse?.data.find(
                    (target) => target.id === option
                  )?.name,
                }))
              )
            );
          }
        }}
        isClearable={false}
        defaultValue={peopleState.filters.project.map((option) => option.value)}
        placeholder={"Cerca per progetto"}
        options={
          databaseState.projectsForFiltersResponse
            ? databaseState.projectsForFiltersResponse.data.map((target) => ({
                label: target.name,
                value: target.id,
              }))
            : []
        }
        noOptionsMessage="Non sono presenti progetti."
      />
    </AccordionFilter>
  );
}
