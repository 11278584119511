import React from "react";
import "./ProgressBar.css";
import { ProgressBarProps } from "./ProgressBar.types";

const ProgressBar: React.FC<ProgressBarProps> = ({ ...props }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "12px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "4px",
          width: "100%",
          borderRadius: "16px",
          backgroundColor: "rgba(230, 231, 249, 1)",
        }}
      >
        <div
          style={{
            height: "100%",
            width: props.value + "%",
            backgroundColor: "rgba(63, 72, 243, 1)",
            borderRadius: "16px",
          }}
        />
      </div>
      <span className="progress-bar-value">{props.value}%</span>
    </div>
  );
};

export default ProgressBar;
