import { PillsColor } from "../../ui/molecules/pills/Pills.types";
import { CompanyDTO } from "../database/companies/dto";
import { PersonDTO } from "../database/people/dto";
import { ProjectDTO } from "../projects/dto";
import { UserDTO } from "../users/dto";

export interface TargetListDTO {
  categories: string[];
  commercials: UserDTO[];
  contacts: number;
  companyContacts: null | { [key: string]: TargetListContactInfo };
  departments: string[];
  employeesNumber: string;
  id: string;
  name: string;
  leads: number;
  drafts: number;
  personContacts: null | { [key: string]: TargetListContactInfo };
  projectId: string;
  project: ProjectDTO;
  revenue: string;
  roles: string;
  sectors: string[];
  state: TargetListStatusEnum;
}

export interface TargetListContactInfo {
  contactId: string;
  contactState: ContactStateEnum;
  lastContactDate: null | Date;
  notes: null | string;
}

export interface FindAllTargetListsResponse {
  data: TargetListDTO[];
  next: boolean;
  page: number;
  perPage: number;
  prev: false;
  total: number;
  totalPage: number;
}

export interface FindAllTargetListsFilters {
  commercial: string[];
  contacts: string;
  itemsPerPage: number;
  name: string;
  project: string[];
  order: boolean;
  page: number;
  sector: string[];
  sort: keyof TargetListDTO;
  state: TargetListStatusEnum[];
  teamLeader: string[];
}

export interface NewTargetListRequest {
  projectId: string;
  name: string;
  commercialIds: string[];
  departments: string[];
  roles: string[];
  revenue: string;
  employeesNumber: string;
  sectors: string[];
  categories: string[];
  particularityId: string;
}

export enum TargetListPageEnum {
  LIST,
  ADD,
  CSV,
  DB,
  TL,
}

export enum TargetListStatusEnum {
  TOSTART = "TOSTART",
  INPROGRESS = "INPROGRESS",
  SUSPENDED = "SUSPENDED",
  CONCLUDED = "CONCLUDED",
}

export interface ImportPersonDTO {
  name: string[];
  surname: string[];
  role: string[];
  department: string[];
  phone: string[];
  linkedinProfile: string[];
  privacy: string[];
  companyName: string[];
  notes: string[];
  lastContactDate: string[];
  email: string[];
  companyVat: string[];
  companyPhone: string[];
  contactState: string[];
}

export interface ImportCompanyDTO {
  name: string[];
  vat: string[];
  address: string[];
  zipCode: string[];
  city: string[];
  province: string[];
  countryRegion: string[];
  state: string[];
  email: string[];
  website: string[];
  revenue: string[];
  employeesNumber: string[];
  atecoCode: string[];
  atecoDescription: string[];
  sector: string[];
  category: string[];
  phone: string[];
  contactState: string[];
  lastContactDate: string[];
  notes: string[];
}

export enum ContactStateEnum {
  DRAFT = "DRAFT",
  LEADTAKEN = "LEADTAKEN",
  TORECALL = "TORECALL",
  KO = "KO",
  INCORRECT = "INCORRECT",
}

export const targertListsStatePillMap = new Map<
  TargetListStatusEnum,
  { label: string; color: PillsColor }
>([
  [TargetListStatusEnum.TOSTART, { label: "Da iniziare", color: "gray" }],
  [TargetListStatusEnum.CONCLUDED, { label: "Concluso", color: "green" }],
  [TargetListStatusEnum.INPROGRESS, { label: "In corso", color: "blue" }],
  [TargetListStatusEnum.SUSPENDED, { label: "Sospeso", color: "orange" }],
]);

export const contactsStatePillMap = new Map<
  ContactStateEnum,
  { label: string; color: PillsColor }
>([
  [ContactStateEnum.DRAFT, { label: "In attesa", color: "blue" }],
  [ContactStateEnum.INCORRECT, { label: "Info errate", color: "gray" }],
  [ContactStateEnum.KO, { label: "Ko", color: "red" }],
  [ContactStateEnum.LEADTAKEN, { label: "Lead preso", color: "green" }],
  [ContactStateEnum.TORECALL, { label: "Da richiamare", color: "orange" }],
]);

export const columnOrderMapKey = new Map<string, string>([
  ["Settore", "sector"],
  ["Nome", "name"],
  ["Team Leader", "project.teamLeader"],
  ["Commerciali", "commercials.name"],
  ["Contatti", "contacts"],
  ["Lead", "contacts"],
  ["Da lavorare", "contacts"],
  ["Stato", "state"],
]);
