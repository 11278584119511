import axios from "axios";
import qs from "qs";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { comuneCapMap } from "../../../lib/places/cap";
import { regions } from "../../../lib/places/italianRegions";
import { provinceComuniMap } from "../../../lib/places/listaComuni";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { groupBy } from "../../../lib/utils";
import { GlobeIcon } from "../../../ui/icons/globe";
import { MailIcon } from "../../../ui/icons/mail";
import { PhoneCallIcon } from "../../../ui/icons/phoneCall";
import { SelectCustomAsync } from "../../../ui/molecules/asyncSelect";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import { ContactStateEnum } from "../../targetList/dto";
import { changeContactState, findTargetListById, setChangeContactStateStatus } from "../../targetList/slice";
import { PlaceFeatureDTO } from "../dto";
import {
    setEditCompanyAddress,
    setEditCompanyAtecoCode,
    setEditCompanyCap,
    setEditCompanyCategory,
    setEditCompanyCity,
    setEditCompanyEmail,
    setEditCompanyEmployeesNumber,
    setEditCompanyName,
    setEditCompanyPhone,
    setEditCompanyProvince,
    setEditCompanyRegion,
    setEditCompanyRevenue,
    setEditCompanySector,
    setEditCompanyState,
    setEditCompanyVat,
    setEditCompanyWebSite,
    setSelectedCompany,
} from "./slice";

export function EditCompanyForm() {
  const companiesState = useAppSelector((state) => state.companies);
  const preferencesState = useAppSelector((state) => state.preferences);
  const targetListState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  const [isoCountries, setIsoCountries] = useState<
    { value: string; code: string }[]
  >([
    { value: "Italia", code: "IT" },
    { value: "Francia", code: "FR" },
    { value: "Germania", code: "DE" },
    { value: "Stati Uniti d'America", code: "US" },
    { value: "Regno Unito", code: "UK" },
  ]);

  const defaultOptions = [
    { label: "Italia", value: "Italia" },
    { label: "Francia", value: "Francia" },
    { label: "Germania", value: "Germania" },
    { label: "Stati Uniti d'America", value: "Stati Uniti" },
    { label: "Regno Unito", value: "Regno Unito" },
  ];

  const fetchCountries = async (
    inputValue: string
  ): Promise<{ value: string; label: string }[]> => {
    if (!inputValue) {
      return defaultOptions;
    }

    try {
      const response = await axios.get(
        "https://api.mapbox.com/search/geocode/v6/forward",
        {
          params: {
            q: inputValue,
            access_token:
              "pk.eyJ1IjoiZ2l1bGlhbWFkZmFybSIsImEiOiJjbHphemJlbmYwcWwyMmtzZHE4dWZxNXNkIn0.RomI9T9UfQnlT1iEu3ieNw",
            types: ["country"],
            language: "it",
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              arrayFormat: "repeat",
              encode: false,
            });
          },
        }
      );

      const countries = response.data.features.map(
        (feature: PlaceFeatureDTO) => ({
          label: feature.properties.name,
          value: feature.properties.name,
        })
      );

      setIsoCountries(
        response.data.features.map((feature: PlaceFeatureDTO) => ({
          code: feature.properties.context.country.country_code,
          value: feature.properties.name,
        }))
      );

      return countries;
    } catch (error) {
      console.error("Errore durante il fetch delle nazioni: ", error);
      return [];
    }
  };

  const fetchCities = async (
    inputValue: string
  ): Promise<{ value: string; label: string }[]> => {
    if (!inputValue) {
      return defaultOptions;
    }

    try {
      const response = await axios.get(
        "https://api.mapbox.com/search/geocode/v6/forward",
        {
          params: {
            q: inputValue,
            access_token:
              "pk.eyJ1IjoiZ2l1bGlhbWFkZmFybSIsImEiOiJjbHphemJlbmYwcWwyMmtzZHE4dWZxNXNkIn0.RomI9T9UfQnlT1iEu3ieNw",
            types: ["place"],
            language: "it",
            country: isoCountries.find(
              (country) =>
                country.value === companiesState.editCompanyRequest.state
            )?.code,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              arrayFormat: "repeat",
              encode: false,
            });
          },
        }
      );

      const countries = response.data.features.map(
        (feature: PlaceFeatureDTO) => ({
          label: feature.properties.name,
          value: feature.properties.name,
        })
      );

      return countries;
    } catch (error) {
      console.error("Errore durante il fetch delle nazioni: ", error);
      return [];
    }
  };

  const provinceFilters = regions
    .filter(
      (region) =>
        companiesState.editCompanyRequest.countryRegion === region.name
    )
    .flatMap((regions) => regions.provinces);

  const comuni = () => {
    let options: { value: string; label: string }[] = [];
    provinceComuniMap
      .get(companiesState.editCompanyRequest.province ?? "")
      ?.forEach((comune) => {
        options.push({ label: comune, value: comune });
      });

    return options.sort((a, b) => a.value.localeCompare(b.value));
  };

  const cap = () => {
    let options: { value: string; label: string }[] = [];

    comuneCapMap
      .get(companiesState.editCompanyRequest.city ?? "")
      ?.forEach((comune) => {
        options.push({ label: comune, value: comune });
      });

    return options.sort((a, b) => a.value.localeCompare(b.value));
  };

  useEffect(() => {
    if (companiesState.findCompanyStatus === "successfully") {
      dispatch(setEditCompanyVat(companiesState.findCompanyResponse?.vat));
      dispatch(setEditCompanyName(companiesState.findCompanyResponse?.name));
      dispatch(
        setEditCompanySector(companiesState.findCompanyResponse?.sector)
      );
      dispatch(
        setEditCompanyCategory(companiesState.findCompanyResponse?.category)
      );
      dispatch(
        setEditCompanyAtecoCode(companiesState.findCompanyResponse?.atecoCode)
      );
      dispatch(
        setEditCompanyAddress(companiesState.findCompanyResponse?.address)
      );
      dispatch(
        setEditCompanyRegion(companiesState.findCompanyResponse?.countryRegion)
      );
      dispatch(setEditCompanyState(companiesState.findCompanyResponse?.state));
      dispatch(setEditCompanyCity(companiesState.findCompanyResponse?.city));
      dispatch(
        setEditCompanyProvince(companiesState.findCompanyResponse?.province)
      );
      dispatch(setEditCompanyCap(companiesState.findCompanyResponse?.zipCode));
      dispatch(
        setEditCompanyWebSite(companiesState.findCompanyResponse?.website)
      );
      dispatch(setEditCompanyEmail(companiesState.findCompanyResponse?.email));
      dispatch(
        setEditCompanyRevenue(companiesState.findCompanyResponse?.revenue)
      );
      dispatch(setEditCompanyPhone(companiesState.findCompanyResponse?.phone));
      dispatch(
        setEditCompanyEmployeesNumber(
          companiesState.findCompanyResponse?.employeesNumber
        )
      );
    }
  }, [companiesState.findCompanyStatus]);

  useEffect(() => {
    if (companiesState.editCompanyRequest.atecoCode === undefined) {
      dispatch(setEditCompanySector(undefined));
      dispatch(setEditCompanyCategory(undefined));
    } else {
      const category = preferencesState.findAllAtecosResponse.find(
        (_ateco) =>
          _ateco.atecoCode === companiesState.editCompanyRequest.atecoCode
      )?.category;
      const sector = preferencesState.findAllAtecosResponse.find(
        (_ateco) =>
          _ateco.atecoCode === companiesState.editCompanyRequest.atecoCode
      )?.sector;

      if (category && companiesState.editCompanyRequest.category !== category) {
        dispatch(setEditCompanyCategory(category));
      }
      if (sector && companiesState.editCompanyRequest.sector !== sector) {
        dispatch(setEditCompanySector(sector));
      }
    }
  }, [companiesState.editCompanyRequest.atecoCode]);

  useEffect(() => {
    if (!companiesState.editCompanyRequest.sector) {
      dispatch(setEditCompanyCategory(undefined));
    } else if (!companiesState.editCompanyRequest.atecoCode) {
      const categories = preferencesState.findAllAtecosResponse.find(
        (_ateco) => _ateco.sector === companiesState.editCompanyRequest.sector
      )?.category;
      dispatch(setEditCompanyCategory(categories));
    }
  }, [companiesState.editCompanyRequest.sector]);

  const filteredAtecos = preferencesState.findAllAtecosResponse.filter(
    (ateco) =>
      companiesState.editCompanyRequest.atecoCode
        ? companiesState.editCompanyRequest.atecoCode === ateco.atecoCode
        : ateco
  );

  const filteredAtecosCategory = preferencesState.findAllAtecosResponse.filter(
    (ateco) =>
      companiesState.editCompanyRequest.atecoCode
        ? companiesState.editCompanyRequest.atecoCode === ateco.atecoCode
        : companiesState.editCompanyRequest.sector
        ? companiesState.editCompanyRequest.sector === ateco.sector
        : ateco
  );

  useEffect(() => {
    if (targetListState.changeContactStateStatus === "successfully") {
      dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
      dispatch(setChangeContactStateStatus('idle'))
      dispatch(setSelectedCompany(undefined))
    }
  }, [targetListState.changeContactStateStatus]);

  return (
    <div key={0} className="px-6 flex flex-col gap-4 relative w-full">
      {window.location.pathname.includes("target-list") && (
        <SelectCustom
          menuPortalTargetIsNotBody
          placeholder={"Selezion un esito"}
          label="Esito"
          onChange={(e) => {
            dispatch(
              changeContactState({
                targetListId: targetListState.findTargetListResponse?.id!,
                type: "companies",
                contactState: e as ContactStateEnum,
                contactId: companiesState.findCompanyResponse?.id!,
              })
            );
          }}
          defaultValue={
            targetListState.findTargetListResponse?.companyContacts &&
            targetListState.findTargetListResponse?.companyContacts[
              companiesState.findCompanyResponse?.id!
            ]
              ? targetListState.findTargetListResponse?.companyContacts[
                  companiesState.findCompanyResponse?.id!
                ].contactState.toString()
              : undefined
          }
          options={[
            {
              label: "Lead preso",
              value: ContactStateEnum.LEADTAKEN.toString(),
            },
            {
              label: "Da richiamare",
              value: ContactStateEnum.TORECALL.toString(),
            },
            {
              label: "Ko",
              value: ContactStateEnum.KO.toString(),
            },
            {
              label: "Info errate",
              value: ContactStateEnum.INCORRECT.toString(),
            },
            {
              label: "In attesa",
              value: ContactStateEnum.DRAFT.toString(),
            },
          ]}
        />
      )}
      <Input
        label="P. IVA"
        defaultValue={companiesState.editCompanyRequest?.vat}
        value={companiesState.editCompanyRequest?.vat}
        disabled={companiesState.findCompanyResponse?.active}
        onChangeText={(text) => dispatch(setEditCompanyVat(text))}
      />
      <NumericFormat
        prefix="€ "
        value={companiesState.editCompanyRequest?.revenue}
        defaultValue={companiesState.editCompanyRequest?.revenue}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
        label="Fatturato"
        disabled={companiesState.findCompanyResponse?.active}
        customInput={Input}
        id={"company-to-confirm-price"}
        key={"company-to-confirm-price"}
        placeholder={"Fatturato"}
        onValueChange={(e) => {
          dispatch(setEditCompanyRevenue(e.floatValue));
        }}
      />
      <div className="flex flex-col gap-[6px]">
        <span
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16px",
            textAlign: "left",
            color: "rgba(78, 94, 126, 1)",
          }}
        >
          Cod. Ateco
        </span>
        <SelectCustom
          menuPortalTargetIsNotBody
          disabled={companiesState.findCompanyResponse?.active}
          onChange={(e) => {
            if (e === undefined || typeof e === "string") {
              dispatch(setEditCompanyAtecoCode(e));
            }
          }}
          isClearable={true}
          defaultValue={companiesState.editCompanyRequest?.atecoCode}
          placeholder={"Cerca per ateco"}
          options={preferencesState.findAllAtecosResponse.map((ateco) => ({
            value: ateco.atecoCode,
            label: ateco.atecoCode + " - " + ateco.atecoDescription,
          }))}
          noOptionsMessage="Non sono presenti ateco."
        />
      </div>
      <div className="flex flex-col gap-[6px]">
        <span
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16px",
            textAlign: "left",
            color: "rgba(78, 94, 126, 1)",
          }}
        >
          Settore
        </span>
        <SelectCustom
          menuPortalTargetIsNotBody
          disabled={companiesState.findCompanyResponse?.active}
          isClearable
          onChange={(e) => {
            if (e === undefined || typeof e === "string") {
              dispatch(setEditCompanySector(e));
            }
          }}
          defaultValue={companiesState.editCompanyRequest?.sector}
          placeholder={"Cerca per settore"}
          options={groupBy(filteredAtecos, "sector").map((sector) => ({
            value: sector.key,
            label: sector.key,
          }))}
          noOptionsMessage="Non sono presenti settori."
        />
      </div>
      <div className="flex flex-col gap-[6px]">
        <span
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16px",
            textAlign: "left",
            color: "rgba(78, 94, 126, 1)",
          }}
        >
          Categoria
        </span>
        <SelectCustom
          menuPortalTargetIsNotBody
          disabled={companiesState.findCompanyResponse?.active}
          isClearable
          onChange={(e) => {
            if (e === undefined || typeof e === "string") {
              dispatch(setEditCompanyCategory(e));
            }
          }}
          defaultValue={companiesState.editCompanyRequest?.category}
          placeholder={"Cerca per categoria"}
          options={groupBy(filteredAtecosCategory, "category").map(
            (sector) => ({ value: sector.key, label: sector.key })
          )}
          noOptionsMessage="Non sono presenti categorie."
        />
      </div>
      <Input
        label="Sede legale"
        value={companiesState.editCompanyRequest?.address}
        onChangeText={(text) => dispatch(setEditCompanyAddress(text))}
      />
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-[6px]">
          <span
            style={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "16px",
              textAlign: "left",
              color: "rgba(78, 94, 126, 1)",
            }}
          >
            Stato
          </span>
          <SelectCustomAsync
            loadOptions={fetchCountries}
            defaultOptions={defaultOptions}
            isClearable
            onChange={(e) => {
              //@ts-ignore
              dispatch(setEditCompanyState(e === null ? undefined : e.value));
              dispatch(setEditCompanyCity([]));
              dispatch(setEditCompanyRegion([]));
            }}
            noOptionsMessage="Stato non trovato"
            placeholder="Seleziona una nazione..."
            defaultValue={
              companiesState.editCompanyRequest.state
                ? {
                    label: companiesState.editCompanyRequest.state,
                    value: companiesState.editCompanyRequest.state,
                  }
                : undefined
            }
          />
        </div>
        {companiesState.editCompanyRequest.state !== undefined &&
          companiesState.editCompanyRequest.state === "Italia" && (
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-[6px]">
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "16px",
                    textAlign: "left",
                    color: "rgba(78, 94, 126, 1)",
                  }}
                >
                  Regione
                </span>
                <SelectCustom
                  menuPortalTargetIsNotBody
                  onChange={(e) => {
                    if (e === undefined || typeof e === "string") {
                      dispatch(setEditCompanyRegion(e));
                    }
                  }}
                  defaultValue={companiesState.editCompanyRequest.countryRegion}
                  placeholder={"Seleziona una regione"}
                  options={regions.map((region) => ({
                    value: region.name,
                    label: region.name,
                  }))}
                />
              </div>
              {companiesState.editCompanyRequest.countryRegion && (
                <div className="flex flex-col gap-[6px]">
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "16px",
                      textAlign: "left",
                      color: "rgba(78, 94, 126, 1)",
                    }}
                  >
                    Provincia
                  </span>
                  <SelectCustom
                    menuPortalTargetIsNotBody
                    onChange={(e) => {
                      if (e === undefined || typeof e === "string") {
                        dispatch(setEditCompanyProvince(e));
                      }
                    }}
                    defaultValue={companiesState.editCompanyRequest.province}
                    placeholder={"Seleziona una provincia"}
                    options={provinceFilters.map((province) => ({
                      value: province.code,
                      label: province.name,
                    }))}
                  />
                </div>
              )}
              {companiesState.editCompanyRequest.province && (
                <div className="flex flex-col gap-[6px]">
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "16px",
                      textAlign: "left",
                      color: "rgba(78, 94, 126, 1)",
                    }}
                  >
                    Città
                  </span>
                  <SelectCustom
                    menuPortalTargetIsNotBody
                    onChange={(e) => {
                      if (e === undefined || typeof e === "string") {
                        dispatch(setEditCompanyCity(e));
                      }
                    }}
                    defaultValue={companiesState.editCompanyRequest.city}
                    placeholder={"Seleziona un comune"}
                    options={comuni()}
                  />
                </div>
              )}
              {companiesState.editCompanyRequest.city && (
                <div className="flex flex-col gap-[6px]">
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "16px",
                      textAlign: "left",
                      color: "rgba(78, 94, 126, 1)",
                    }}
                  >
                    CAP
                  </span>
                  <SelectCustom
                    menuPortalTargetIsNotBody
                    onChange={(e) => {
                      if (e === undefined && typeof e === "string") {
                        dispatch(setEditCompanyCap(e));
                      }
                    }}
                    defaultValue={companiesState.editCompanyRequest.zipCode?.toString()}
                    placeholder={"Seleziona un CAP"}
                    options={cap()}
                  />
                </div>
              )}
            </div>
          )}
        {companiesState.editCompanyRequest.state !== undefined &&
          companiesState.editCompanyRequest.state !== null &&
          companiesState.editCompanyRequest.state !== "Italia" && (
            <div className="flex flex-col gap-[6px]">
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  textAlign: "left",
                  color: "rgba(78, 94, 126, 1)",
                }}
              >
                Città
              </span>
              <SelectCustomAsync
                loadOptions={fetchCities}
                defaultOptions={[]}
                isClearable
                defaultValue={
                  companiesState.editCompanyRequest.city
                    ? {
                        label: companiesState.editCompanyRequest.city,
                        value: companiesState.editCompanyRequest.city,
                      }
                    : undefined
                }
                onChange={(e) => {
                  //@ts-ignore
                  dispatch(setEditCompanyCity(e === null ? undefined : e.value)
                  );
                }}
                noOptionsMessage="Città non trovata"
                placeholder="Seleziona una città..."
              />
            </div>
          )}
      </div>
      <Input
        label="Sito web"
        endIcon={<GlobeIcon color={""} size={0} />}
        value={companiesState.editCompanyRequest?.website}
        onChangeText={(text) => dispatch(setEditCompanyWebSite(text))}
      />
      <Input
        label="Email"
        endIcon={<MailIcon color={""} size={0} />}
        value={companiesState.editCompanyRequest?.email}
        onChangeText={(text) => dispatch(setEditCompanyEmail(text))}
      />
      <Input
        label="Telefono"
        disabled={companiesState.findCompanyResponse?.active}
        endIcon={<PhoneCallIcon color={""} size={0} />}
        value={companiesState.editCompanyRequest?.phone}
        onChangeText={(text) => dispatch(setEditCompanyPhone(text))}
      />
    </div>
  );
}
