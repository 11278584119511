import Keycloak from "keycloak-js";

const urlMap = new Map<string, string>([
    ['https://staging-app.aisales.it', 'https://staging-auth.aisales.it/auth'],
    ['https://app.aisales.it', 'https://auth.aisales.it/auth'],
    ['http://localhost:3000', 'http://localhost:8081/auth'],
])

export const keycloak = new Keycloak({
    realm: "commercialidigitali",
    url: urlMap.get(window.location.origin),
    clientId: "frontend",
})
