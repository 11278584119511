import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { encodeBase64 } from "../../../lib/utils";
import { Layout } from "../../../ui/layout";
import Button from "../../../ui/molecules/button";
import FileUploader from "../../../ui/organisms/fileUploader";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import { Form } from "./form";
import {
  newProject,
  setNewProjectCommercialIds,
  setNewProjectCustomerId,
  setNewProjectDescription,
  setNewProjectName,
  setNewProjectStatus,
  setNewProjectTeamLeaderId,
  setSelectedProject,
  setValidateNewProjectCommercialIds,
  setValidateNewProjectCustomerId,
  setValidateNewProjectName,
  setValidateNewProjectStatus,
  setValidateNewProjectTeamLeaderId,
  validateNewProject,
} from "../slice";
import {
  findClients,
  findCollaborators,
  findCommercials,
  findTeamLeaders,
} from "../../users/slice";
import { Spinner } from "../../../ui/molecules/spinner";
// import { UserRole, UserRoleMap } from "./dto";
// import { findClients, findCollaborators, newUser, setCreateUserCollaborators, setCreateUserCustomerId, setCreateUserEmail, setCreateUserName, setCreateUserPhone, setCreateUserRole, setCreateUserStatus, setCreateUserSurname, setValidateNewUserEmail, setValidateNewUserName, setValidateNewUserPhone, setValidateNewUserRole, setValidateNewUserStatus, setValidateNewUserSurname, validateNewUser } from "./slice";

export function NewProject() {
  const projectsState = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const [direction, setDirection] = useState<"left" | "right">("left");
  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (projectsState.newProjectStatus === "failed") {
      timeoutId = setTimeout(() => {
        dispatch(setNewProjectStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [projectsState.newProjectStatus]);

  useEffect(() => {
    if (projectsState.validateNewProject.status === "successfully") {
      dispatch(newProject(projectsState.newProjectRequest));
      dispatch(setValidateNewProjectStatus("idle"));
    }
  }, [projectsState.validateNewProject.status]);

  useEffect(() => {
    if (projectsState.selectedProject !== undefined) {
      navigation(
        "/edit-project/" + projectsState.selectedProject + "/specifics"
      );
      dispatch(setSelectedProject(undefined));
    }
  }, [projectsState.selectedProject]);

  // useEffect(() => {
  //     if (projectsState.newProjectStatus === 'successfully') {
  //         navigation('/projects')
  //     }
  // }, [projectsState.newProjectStatus, dispatch])

  useEffect(() => {
    dispatch(findTeamLeaders());
    dispatch(findCommercials());
    dispatch(findClients());
    dispatch(setNewProjectName(""));
    dispatch(setNewProjectCommercialIds([]));
    dispatch(setNewProjectCustomerId(undefined));
    dispatch(setNewProjectTeamLeaderId(undefined));
    dispatch(setNewProjectDescription(undefined));
    dispatch(setNewProjectStatus("idle"));
    dispatch(setValidateNewProjectCommercialIds(false));
    dispatch(setValidateNewProjectName(false));
    dispatch(setValidateNewProjectCustomerId(false));
    dispatch(setValidateNewProjectTeamLeaderId(false));
    dispatch(setValidateNewProjectStatus("idle"));
  }, []);

  return (
    <Layout
      menuItem={MenuItems.PROJECTS}
      breadcrumbItems={["Progetti", "Nuovo progetto"]}
      headerLabel={"Nuovo progetto"}
      headerBackPath={"/projects"}
      headerChildren={
        <div className="flex items-center h-[100%] w-[100%] justify-between">
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => {
              setTabValue(e);
            }}
            value={tabValue}
            items={[
              {
                icon: <></>,
                label: "Specifiche",
              },
              {
                icon: <></>,
                disabled: true,
                label: "Liste target (0)",
              },
            ]}
          />
          {projectsState.newProjectStatus === "loading" ||
          projectsState.validateNewProject.status === "loading" ? (
            <Spinner />
          ) : (
            <Button
              size={"sm"}
              iconPosition={"off"}
              label="Crea progetto"
              variant={"solid"}
              color={"blue"}
              onClick={() => {
                dispatch(validateNewProject(projectsState.newProjectRequest));
              }}
            />
          )}
        </div>
      }
    >
      <TabPages
        direction={direction}
        setDirection={(value) => setDirection(value)}
        pages={[
          <div key={0} className="max-h-full">
            <Form />
          </div>,
          <div key={1} className="h-full"></div>,
        ]}
        value={tabValue}
        setValue={(e) => {
          setTabValue(e);
        }}
      />
    </Layout>
  );
}
