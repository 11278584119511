import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { MoreHorizontalIcon } from "../../../../ui/icons/moreHorizontal";
import Button from "../../../../ui/molecules/button";
import { Spinner } from "../../../../ui/molecules/spinner";
import { Banner } from "../../../../ui/organisms/banner";
import { NotFound } from "../../../../ui/organisms/notFound";
import { TargetListsFilters } from "../../../targetList/filters";
import { TargetListsList } from "../../../targetList/list";
import { OptionsTarget } from "../../../targetList/options/options";
import {
  setDeleteTargetListStatus,
  setNewTargetListProjectId,
  setOptionsActive,
} from "../../../targetList/slice";
import { EditProjectPage } from "../../dto";
import { setEditProjectPage } from "../../slice";

export function TargetLists() {
  const projectsState = useAppSelector((state) => state.projects);
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col bg-white rounded-2xl shadow-sm m-6">
      <div className="absolute flex flex-col gap-4 right-6">
        <Banner
          label={"Lista target eliminata."}
          visible={targetListsState.deleteTargetListStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setDeleteTargetListStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'eliminazione della lista target."
          }
          visible={targetListsState.deleteTargetListStatus === "failed"}
          closeAction={function (): void {
            dispatch(setDeleteTargetListStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      <div className="p-4 flex justify-between items-center">
        <span className="text-heading-xs font-semibold text-neutral-800">
          Liste Target del progetto
        </span>
        <div className="flex items-center gap-2">
          <Button
            size={"sm"}
            iconPosition={"off"}
            variant={"outline"}
            color={"blue"}
            label="Aggiungi lista target"
            onClick={() => {
              dispatch(setEditProjectPage(EditProjectPage.ADDLIST));
              dispatch(
                setNewTargetListProjectId(projectsState.findProjectResponse?.id)
              );
            }}
          />
          <Button
            size={"sm"}
            iconPosition={"only"}
            variant={targetListsState.optionsActive ? "outline" : "ghost"}
            color={targetListsState.optionsActive ? "blue" : "gray"}
            onClick={() =>
              dispatch(setOptionsActive(!targetListsState.optionsActive))
            }
            icon={<MoreHorizontalIcon color={""} size={0} />}
          />
        </div>
      </div>
      {projectsState.findProjectStatus === "loading" ? (
        <div className="w-full h-[50%] flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <TargetListsFilters />
          {targetListsState.findAllTargetListsResponse === undefined ||
          !targetListsState.findAllTargetListsResponse?.data ||
          targetListsState.findAllTargetListsResponse?.data?.length === 0 ? (
            <NotFound />
          ) : (
            <TargetListsList />
          )}
        </>
      )}
      <div
        style={{ height: "calc(100%)" }}
        className={clsx(
          "absolute right-0 bottom-0 z-50 flex-shrink-0 w-[290px] transition-all",
          {
            visible: targetListsState.optionsActive,
            hidden: !targetListsState.optionsActive,
          }
        )}
      >
        <div className="bg-white shadow-md h-full">
          <OptionsTarget />
        </div>
      </div>
    </div>
  );
}
