import Papa from "papaparse"
import { ReactElement, useState } from "react"
import { AssociateForCSV } from "./associate"
import { UploadFileForCSV } from "./uploadFile"

export function ImportFromCSV() {
    const [step, setStep] = useState<number>(0)

    const [error, setError] = useState(false)
    const [name, setName] = useState('')
    const [delimiter, setDelimiter] = useState('')
    const [fields, setFields] = useState<string[]>([])
    const [file, setFile] = useState<File | null>(null)
    const [rows, setRows] = useState<Record<string, any>[]>([]);

    const handleFileChange = (e: File[]) => {
        const file = e[0]
        setError(false)
        if (file) {
            if (file.type !== 'text/csv') {
                setError(true)
                setName('')
                setDelimiter('')
                setFields([])
                setRows([]);
                setFile(null)
            } else {
                setName(file.name)
                Papa.parse(file, {
                    complete: (result) => {
                        setDelimiter(result.meta.delimiter)
                        setFields(result.meta.fields ?? [])
                        //@ts-ignore
                        setRows(result.data);
                        setFile(file)
                    },
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                });
            }
        }
    };

    const csvStepMap = new Map<number, ReactElement>([
        [0, <UploadFileForCSV step={step} setStep={e => setStep(e)} handleChange={e => handleFileChange(e)} error={error} file={file} />],
        [1, <AssociateForCSV step={step} setStep={e => setStep(e)} name={name} delimiter={delimiter} fields={fields} file={file} rows={rows} />],
    ])
    return (
        <>{csvStepMap.get(step)}</>
    )
}