import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import Button from "../../ui/molecules/button";
import Input from "../../ui/molecules/input/Input";
import { Spinner } from "../../ui/molecules/spinner";
import {
  createParticularity,
  findAllParticularities,
  setCreateParticularityStatus,
  setShowNewParticularityModal,
} from "./slice";

export function NewParticularity() {
  const particularitiesState = useAppSelector((state) => state.particularities);

  const dispatch = useAppDispatch();

  const [name, setName] = useState<string>("");
  const [errorName, setErrorName] = useState<boolean>(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      particularitiesState.createParticularityStatus === "failed" ||
      particularitiesState.createParticularityStatus === "successfully"
    ) {
      dispatch(setShowNewParticularityModal(false));
      if (particularitiesState.createParticularityStatus === "successfully") {
        dispatch(
          findAllParticularities({
            itemsPerPage: 15,
            order: true,
            page: 0,
            sort: "name",
            name: ''
          })
        );
      }
      timeoutId = setTimeout(() => {
        dispatch(setCreateParticularityStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [particularitiesState.createParticularityStatus]);

  useEffect(() => {
    if (!particularitiesState.showNewParticularityModal) {
      setErrorName(false);
      setName("");
    }
  }, [particularitiesState.showNewParticularityModal]);

  return (
    <div
      onClick={() => dispatch(setShowNewParticularityModal(false))}
      className={clsx(
        "transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]",
        {
          block: particularitiesState.showNewParticularityModal,
          hidden: !particularitiesState.showNewParticularityModal,
        }
      )}
    >
      {particularitiesState.createParticularityStatus === "loading" ? (
        <Spinner />
      ) : (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="transition-all flex flex-col items-center gap-8 p-[20px] bg-white rounded-[12px] opacity-100 shadow-sm min-w-[400px]"
        >
          <div className="flex flex-col items-center gap-2">
            <span className="text-text-lg font-semibold text-neutral-800">
              Aggiungi particolarità
            </span>
            <span className="text-text-md text-center font-normal text-neutral-500">
              Per creare una nuova particolarità inserisci il nome e conferma.
            </span>
          </div>
          <div className="flex flex-col w-full h-full gap-3">
            <Input
              placeholder="Nome particolarità"
              error={errorName}
              value={name}
              onChangeText={(e) => setName(e)}
              supportingText={
                errorName ? "Inserisci il nome della particolarità" : undefined
              }
            />
          </div>
          <div className="w-full flex flex-1 gap-3 mt-3">
            <div className="w-1/2">
              <Button
                fullWidth
                size={"sm"}
                iconPosition={"off"}
                variant={"outline"}
                color={"gray"}
                label="Annulla"
                onClick={() => dispatch(setShowNewParticularityModal(false))}
              />
            </div>
            <div className="w-1/2">
              <Button
                fullWidth
                size={"sm"}
                iconPosition={"off"}
                variant={"solid"}
                color={"blue"}
                label="Conferma"
                onClick={() => {
                  if (!name || name === undefined || name === "") {
                    setErrorName(true);
                  } else {
                    dispatch(
                      createParticularity({
                        name: name,
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
