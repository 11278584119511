import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Spinner } from "../../../ui/molecules/spinner";
import { Banner } from "../../../ui/organisms/banner";
import { NotFound } from "../../../ui/organisms/notFound";
import { setTabValue } from "../slice";
import { Filters } from "./filters";
import { CompaniesList } from "./list";
import {
  findAllCompanies,
  setDeleteCompanyStatus,
  setEditCompanyStatus,
  setFindAllCompaniesFiltersPage,
  setSelectedCompany,
} from "./slice";

export function Companies() {
  const databaseState = useAppSelector((state) => state.database);
  const companiesState = useAppSelector((state) => state.companies);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isFirstLoadCompanies, setIsFirstLoadCompanies] = useState(true);

  useEffect(() => {
    dispatch(findAllCompanies({ ...companiesState.filters, targetList: [] }));
    setIsFirstLoadCompanies(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoadCompanies) {
      dispatch(setFindAllCompaniesFiltersPage(0));
      dispatch(findAllCompanies({ ...companiesState.filters, page: 0 }));
    }
  }, [
    companiesState.filters.order,
    companiesState.filters.sort,
    companiesState.filters.name,
    companiesState.filters.atecos,
    companiesState.filters.category,
    companiesState.filters.city,
    companiesState.filters.countryRegion,
    companiesState.filters.department,
    companiesState.filters.employees,
    companiesState.filters.privacy,
    companiesState.filters.revenue,
    companiesState.filters.role,
    companiesState.filters.sector,
    companiesState.filters.smartphone,
    companiesState.filters.state,
    companiesState.filters.zipCode,
    companiesState.filters.targetList,
    companiesState.filters.project,
    companiesState.filters.outcome,
  ]);

  useEffect(() => {
    if (!isFirstLoadCompanies)
      dispatch(findAllCompanies(companiesState.filters));
  }, [companiesState.filters.page]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      companiesState.deleteCompanyStatus === "failed" ||
      companiesState.deleteCompanyStatus === "successfully"
    ) {
      if (companiesState.deleteCompanyStatus === "successfully") {
        dispatch(findAllCompanies(companiesState.filters));
      }
      timeoutId = setTimeout(() => {
        dispatch(setDeleteCompanyStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [companiesState.deleteCompanyStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      companiesState.editCompanyStatus === "failed" ||
      companiesState.editCompanyStatus === "successfully"
    ) {
      if (companiesState.editCompanyStatus === "successfully") {
        dispatch(setSelectedCompany(undefined));
        dispatch(findAllCompanies(companiesState.filters));
      }
      timeoutId = setTimeout(() => {
        dispatch(setEditCompanyStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [companiesState.editCompanyStatus]);

  useEffect(() => {
    if (
      companiesState.importCSVStatus === "failed" ||
      companiesState.importCSVStatus === "successfully"
    ) {
      navigate("/database");
      dispatch(setTabValue(1));
    }
  }, [companiesState.importCSVStatus]);

  return (
    <div className="">
      <div className="absolute flex flex-col gap-4 right-6">
        <Banner
          label={"Azienda eliminata."}
          visible={companiesState.deleteCompanyStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setDeleteCompanyStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'eliminazione dell'azienda."
          }
          visible={companiesState.deleteCompanyStatus === "failed"}
          closeAction={function (): void {
            dispatch(setDeleteCompanyStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={"Azienda aggiornata."}
          visible={companiesState.editCompanyStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setEditCompanyStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={"Si è verificato un errore durante la modifica dell'azienda."}
          visible={companiesState.editCompanyStatus === "failed"}
          closeAction={function (): void {
            dispatch(setEditCompanyStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      <div className="flex max-h-full">
        <div
          className={clsx("transition-all duration-300 w-[25%] flex-shrink-0", {
            block: databaseState.filtersActive,
            hidden: !databaseState.filtersActive,
          })}
        >
          <Filters />
        </div>
        <div
          className={clsx({
            "w-full": !databaseState.filtersActive,
            "w-9/12": databaseState.filtersActive,
          })}
        >
          {companiesState.findAllStatus === "loading" ? (
            <div className="w-full h-[45%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div>
              <div className="h-16 flex items-center bg-white px-4">
                <span className="text-heading-xs font-semibold text-neutral-800">
                  Aziende
                </span>
              </div>
              {companiesState.findAllResponse?.data.length === 0 ||
              companiesState.findAllResponse?.data === null ? (
                <NotFound />
              ) : (
                <CompaniesList />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
