import React, { useEffect, useRef } from "react";
import "./Checkbox.css";
import { CheckboxProps, CheckboxShape, CheckboxSize, } from "./Checkbox.types";

const sizeMap = new Map<CheckboxSize, React.CSSProperties>([
    ['small', { height: '16px', width: '16px' }],
    ['medium', { height: '20px', width: '20px' }],
])

const sizeMapPlaceholder = new Map<CheckboxSize, string>([
    ['small', 'checkbox-placeholder-small'],
    ['medium', 'checkbox-placeholder-medium'],
])

const sizeMapSupportingText = new Map<CheckboxSize, string>([
    ['small', 'checkbox-supporting-text-small'],
    ['medium', 'checkbox-supporting-text-medium'],
])

const shapeMap = new Map<CheckboxShape, React.CSSProperties>([
    ['square', { borderRadius: '4px' }],
    ['circle', { borderRadius: '50%' }],
])

const Checkbox: React.FC<CheckboxProps> = ({
    shape = 'square',
    size = 'small',
    ...props
}) => {


    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = props.indeterminate ? true : false;
        }
    }, [props.indeterminate]);

    return (
        <div className={"checkbox-wrapper checkbox-wrapper-" + size}>
            <label className="checkbox-organizer">
                <input
                    ref={checkboxRef}
                    style={{ ...sizeMap.get(size), ...shapeMap.get(shape), ...{ marginTop: props.placeholder ? '3px' : '0' } }}
                    type="checkbox"
                    checked={props.checked}
                    disabled={props.disabled}
                    onChange={(e) => props.onCheck(e.currentTarget.checked)}
                />
                {
                    props.placeholder &&
                    <div className="checkbox-label-wrapper">
                        {
                            props.placeholder &&
                            <span className={sizeMapPlaceholder.get(size)}>{props.placeholder}</span>
                        }
                        {
                            props.supportingText && props.placeholder &&
                            <span className={sizeMapSupportingText.get(size)}>{props.supportingText}</span>
                        }
                    </div>
                }
            </label>
        </div >
    );
};

export default Checkbox;