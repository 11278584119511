import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { TargetIcon } from "../../../../ui/icons/target";
import { SelectCustom } from "../../../../ui/molecules/select";
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter";
import { TargetListPageEnum } from "../../../targetList/dto";
import { setFindAllCompaniesFiltersTargetList } from "../slice";

export function TargetFilter() {
  const targetListState = useAppSelector((state) => state.targetList);
  const companiesState = useAppSelector((state) => state.companies);
  const databaseState = useAppSelector((state) => state.database);

  const dispatch = useAppDispatch();

  const targetListOptions =
    databaseState.targetListsForFiltersResponse?.data.filter((target) =>
      companiesState.filters.project.length > 0
        ? companiesState.filters.project
            .map((_ateco) => _ateco.value)
            .includes(target.project.id)
        : target
    );

  return (
    <AccordionFilter
      disabled={
        window.location.pathname.includes("target") &&
        targetListState.targetListPage !== TargetListPageEnum.DB
      }
      key={"person-filter-target"}
      label={"Lista target"}
      icon={<TargetIcon color={""} size={0} />}
      showClear={
        companiesState.filters.targetList.length > 0 &&
        (!window.location.pathname.includes("target") ||
          (window.location.pathname.includes("target") &&
            targetListState.targetListPage === TargetListPageEnum.DB))
      }
      options={companiesState.filters.targetList}
      clearAction={function (): void {
        dispatch(setFindAllCompaniesFiltersTargetList([]));
      }}
      deleteFilter={function (option): void {
        dispatch(
          setFindAllCompaniesFiltersTargetList(
            companiesState.filters.targetList.filter(
              (targetList) => targetList.value !== option.value
            )
          )
        );
      }}
    >
      <SelectCustom
        isMulti
        onChange={(e) => {
          if (e !== undefined && typeof e !== "string") {
            dispatch(
              setFindAllCompaniesFiltersTargetList(
                e.map((option) => ({
                  value: option,
                  label: databaseState.targetListsForFiltersResponse?.data.find(
                    (target) => target.id === option
                  )?.name,
                }))
              )
            );
          }
        }}
        isClearable={false}
        defaultValue={companiesState.filters.targetList.map(
          (option) => option.value
        )}
        placeholder={"Cerca per lista target"}
        options={
          targetListOptions
            ? targetListOptions.map((target) => ({
                label: target.name,
                value: target.id,
              }))
            : []
        }
        noOptionsMessage="Non sono presenti liste target."
      />
    </AccordionFilter>
  );
}
