import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { Filters } from "../../../../database/companies/filters";
import { TargetCompaniesList } from "./list";
import { NotFound } from "../../../../../ui/organisms/notFound";
import { Banner } from "../../../../../ui/organisms/banner";
import {
  setEditCompanyStatus,
  setSelectedCompany,
} from "../../../../database/companies/slice";
import { findTargetListById } from "../../../../targetList/slice";
import { useEffect } from "react";

export function TargetListCompaniesList() {
  const databaseState = useAppSelector((state) => state.database);
  const companiesState = useAppSelector((state) => state.companies);
  const targetsListsState = useAppSelector((state) => state.targetList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      companiesState.editCompanyStatus === "failed" ||
      companiesState.editCompanyStatus === "successfully"
    ) {
      if (companiesState.editCompanyStatus === "successfully") {
        dispatch(setSelectedCompany(undefined));
        dispatch(
          findTargetListById(targetsListsState.findTargetListResponse?.id!)
        );
      }
      timeoutId = setTimeout(() => {
        dispatch(setEditCompanyStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [companiesState.editCompanyStatus]);

  return (
    <div className="flex w-full border-t border-neutral-200 transition-all duration-300">
      <div className="fixed top-6 flex flex-col gap-4 right-6">
        <Banner
          label={"Azienda aggiornata."}
          visible={companiesState.editCompanyStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setEditCompanyStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={"Si è verificato un errore durante la modifica dell'azienda."}
          visible={companiesState.editCompanyStatus === "failed"}
          closeAction={function (): void {
            dispatch(setEditCompanyStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      <div
        className={clsx("transition-all duration-300 w-[25%] flex-shrink-0", {
          block: databaseState.filtersActive,
          hidden: !databaseState.filtersActive,
        })}
      >
        <Filters />
      </div>
      <div
        className={clsx("transition-all duration-300", {
          "w-full": !databaseState.filtersActive,
          "w-9/12": databaseState.filtersActive,
        })}
      >
        {companiesState.findAllStatus === "loading" ||
        targetsListsState.findTargetListStatus === "loading" ? (
          <div className="flex w-full items-center justify-center h-[45%]">
            {" "}
            <Spinner />
          </div>
        ) : companiesState.findAllResponse?.total === 0 ? (
          <NotFound />
        ) : (
          <TargetCompaniesList />
        )}
      </div>
    </div>
  );
}
