import { ReactElement } from "react";
import { useAppSelector } from "../../lib/redux/hooks";
import Avatar from "../../ui/molecules/avatar";
import Pills from "../../ui/molecules/pills";
import { targertListsStatePillMap, TargetListDTO } from "./dto";

interface Props {
  column: string;
  target: TargetListDTO;
}
export function Cell(props: Props) {
  const { column, target } = props;
  const usersState = useAppSelector((state) => state.users);
  return new Map<string, ReactElement>([
    [
      "Nome",
      <td className="pr-[4px] pl-4 text-text-sm font-semibold text-neutral-600">
        {target.name}
      </td>,
    ],
    [
      "Team Leader",
      <td className="px-[4px] text-text-sm text-neutral-600 flex items-center gap-2 justify-center h-14">
        <Avatar
          type="single"
          size="sm"
          shape="circle"
          imageUrl={
            usersState.findUsersAvatarsListResponse.find(
              (avatar) => avatar.userId === target.project?.teamLeader.id
            )?.objectId ?? ""
          }
          altTextInitials={
            target.project.teamLeader.name[0] +
            target.project.teamLeader.surname[0]
          }
        />
        <span>
          {(target.project.teamLeader.name ?? "-") +
            " " +
            (target.project.teamLeader.surname ?? "-")}
        </span>
      </td>,
    ],
    [
      "Commerciali",
      <td className="px-[4px] text-text-sm text-neutral-600">
        <div className="flex items-center justify-center">
          <Avatar
            type="group"
            size="sm"
            length={2}
            imageUrls={target.commercials.map((commercial) => ({
              altTextInitials: commercial.name[0] + commercial.surname[0],
              url:
                usersState.findUsersAvatarsListResponse.find(
                  (avatar) => avatar.userId === commercial.id
                )?.objectId ?? "",
            }))}
          />
        </div>
      </td>,
    ],
    [
      "Settore",
      <td className="px-[4px] text-text-sm text-neutral-600">
        <Pills
          size={"xs"}
          label={
            target.sectors[0] +
            (target.sectors.length > 1
              ? " +" + (target.sectors.length - 1)
              : "")
          }
          outline={false}
          emphasis={false}
          color={"blue"}
        />
      </td>,
    ],
    [
      "Contatti",
      <td className="px-[4px] text-text-sm text-neutral-600">
        <div className="flex items-center justify-center">
          <Pills
            size={"xs"}
            label={target.contacts?.toString() ?? "0"}
            outline={false}
            emphasis={false}
            color={"blue"}
          />
        </div>
      </td>,
    ],
    [
      "Lead",
      <td className="px-[4px] text-text-sm text-neutral-600">
        <div className="flex items-center justify-center">
          <Pills
            size={"xs"}
            label={target.leads?.toString() ?? "0"}
            outline={false}
            emphasis={false}
            color={"blue"}
          />
        </div>
      </td>,
    ],
    [
      "Da lavorare",
      <td className="px-[4px] text-text-sm text-neutral-600">
        <div className="flex items-center justify-center">
          <Pills
            size={"xs"}
            label={target.drafts?.toString() ?? "0"}
            outline={false}
            emphasis={false}
            color={"blue"}
          />
        </div>
      </td>,
    ],
    [
      "Stato",
      <td className="px-[4px] text-text-sm text-neutral-600">
        <Pills
          size={"xs"}
          label={targertListsStatePillMap.get(target.state)?.label!}
          outline={false}
          emphasis={false}
          color={targertListsStatePillMap.get(target.state)?.color!}
        />
      </td>,
    ],
  ]).get(column)!;
}
